import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

/**
 * model
 */
import { Scope } from './scopes.model';

export interface ScopesState extends EntityState<Scope> {}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'scopes' })
export class ScopesStore extends EntityStore<ScopesState, Scope> {
  constructor() {
    super();
  }
}
