import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from "@angular/core";
import { ShareStudentsFiltersData } from "../../student-activity/share-students-filters-data";
import { DateSelection } from "./models/date-selection";

@Component({
  selector: 'date-filters',
  templateUrl: './date-filters.component.html',
  styleUrls: ['./date-filters.component.scss']
})
export class DateFiltersComponent implements OnInit {

  ngInitialDates:Object = {};
  ngExcludedDates;
  ngStartTime;
  ngEndTime;
  dateFormat:string = 'Z';
  timeFormat:string = 'H:i';
  altDateFormat:string = 'F j, Y';
  altTimeFormat:string = 'G:i K';

  @Output() outSelectDatesEvent = new EventEmitter<any>();

  /**
   * dom elements
   */
  @ViewChild('excludedDates') excludedDatesRef: ElementRef;

  constructor(
    private sharedFilters: ShareStudentsFiltersData
  ) { }

  ngOnInit() {
    if (this.sharedFilters.getSelectedDates().selected) {
      this.ngInitialDates = {
        from: this.sharedFilters.getSelectedDates().formattedStart(),
        to: this.sharedFilters.getSelectedDates().utcEnd(),
      };

      this.ngStartTime = this.sharedFilters.getSelectedDates().startTime;
      this.ngEndTime = this.sharedFilters.getSelectedDates().endTime;

      this.ngExcludedDates = this.sharedFilters.getSelectedDates().formattedExcludedDates();
    }
  }

  ngAfterViewInit() {
  }

  /**
   * triggered when initial dates are selected
   * @param event
   */
  eventInitialSelectionDates(event) {
    const isRangeSelected = event.selectedDates.length === 2;

    if (isRangeSelected) {
      this.sharedFilters.getSelectedDates().selected = true;
      this.sharedFilters.getSelectedDates().start = event.selectedDates[0];
      this.sharedFilters.getSelectedDates().end = event.selectedDates[1];
    } else {
      this.clearSelectedDates();
    }
  }

  /**
   * triggered when excluded dates are selected
   * @param event
   */
  eventExcludeDates(event) {
    this.sharedFilters.getSelectedDates().excludedDates = event.selectedDates;
  }

  /**
   * triggered when start time is selected
   * @param event
   */
  eventSelectedStartTime(event) {
    const startTime = this.selectTime(event, this.sharedFilters.getSelectedDates().startTime);
    if (startTime) {
      this.sharedFilters.getSelectedDates().startTime = startTime;
    }
  }

  /**
   * triggered when end time is selected
   * @param event
   */
  eventSelectedEndTime(event) {
    const endTime = this.selectTime(event, this.sharedFilters.getSelectedDates().endTime);

    if (endTime) {
      this.sharedFilters.getSelectedDates().endTime = endTime;
    }
  }

  private eventApplyFilters():void {
    this.outSelectDatesEvent.emit();
  }

  private selectTime(event, date: Date):Date {
    if (event.selectedDates.length == 0 || (
        date && date.getTime() === event.selectedDates[0].getTime())) {
      return;
    }

    return new Date(event.selectedDates[0].getTime());
  }

  private clearSelectedDates() {
    this.sharedFilters.setSelectedDates(new DateSelection());
    this.ngExcludedDates = [];
    this.ngStartTime = undefined;
    this.ngEndTime = undefined;
  }
}
