import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { mapTo, timeInterval, map, tap } from 'rxjs/operators';
import { noop } from '@datorama/akita';

import { Path } from './path.model';
import { PathStore } from './path.store';
import { PathQuery } from './path.query';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PathService {

  constructor(
    private pathStore: PathStore,
  ) { }

  /**
   * add path to the pathStore
   * @param path - path to add
   */
  addPath(path: Path) {
    this.pathStore.add(path);
  }
}
