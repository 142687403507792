import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Custom Components
import { AdvancedReportingFiltersComponent } from './advanced-reporting-filters.component';

const advancedReportingFiltersRoutes: Routes = [
  { path: '', component: AdvancedReportingFiltersComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(advancedReportingFiltersRoutes),
  ],
  exports: [
    RouterModule
  ]
})

export class AdvancedReportingFiltersRoutingModule { }
