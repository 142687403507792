import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { PaginatorModule } from 'primeng/paginator';
import { Ng4LoadingSpinnerModule  } from 'ng4-loading-spinner';

/**
 * custom imports
 */
import { SharedModules } from '../shared/shared.module';
import { StudentsListRoutingModule } from './students-list-routing.module';
import { StudentsListComponent } from './students-list.component';
import { SessionSummaryComponent } from './session-summary.component';
import { MaterialModule } from '../material.module';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { EmailService } from '../services/email.service';

@NgModule({
  imports: [
    Ng4LoadingSpinnerModule.forRoot(),
    CommonModule,
    StudentsListRoutingModule,
    TranslateModule.forChild({}),
    FormsModule,
    MaterialModule,
    TableModule,
    TooltipModule,
    PaginatorModule,
    ToastModule,
    // Custom modules
    SharedModules,
  ],
  declarations: [
    StudentsListComponent, SessionSummaryComponent
  ],
  providers: [
    MessageService,
    EmailService
  ]
})

export class StudentsListModule { }
