import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Custom Components
import { ConsolidatedStudentsScoresComponent } from './consolidated-students-scores.component';

const consolidatedStudentsScoresRoutes: Routes = [
  { path: '', component: ConsolidatedStudentsScoresComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(consolidatedStudentsScoresRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class ConsolidatedStudentsScoresRoutingModule { }
