
export class TableColumn {
  field: string;
  header: string;
  filter: boolean;
  tooltip: string;
  sortable: boolean;
  colspan: number;
  rowspan: number;
  addToReportRow: boolean;
  className: string;

  constructor(
    field: string,
    header: string,
    tooltip: string,
    addToReportRow: boolean = true,
    className: string = '',
    filter: boolean = false,
    sortable: boolean = false,
    colspan: number = 1,
    rowspan: number = 1) {
      this.field = field;
      this.header = header;
      this.filter = filter;
      this.tooltip = tooltip;
      this.sortable = sortable;
      this.colspan = colspan;
      this.rowspan = rowspan;
      this.addToReportRow = addToReportRow;
      this.className = className;
  }

  /**
   * increment colspan for scope column
   * @param colspan - quantity to increment
   */
  incrementColspan(colspan) {
    this.colspan += colspan;
  }
}
