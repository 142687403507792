import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * state
 */
import { AdminUser, AdminUsersService } from '../../state/admin-users';
import { Scope, ScopesService } from '../../state/scopes';
import { Goal, GoalsService } from '../../state/goals';
import { Tag, TagsService } from '../../state/tags';

/**
 * third party components
 */
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MessageService } from 'primeng/api';

/**
 * shared
 */
import { Utils } from '../utils';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  /**
   * output events for filters component
   * each component use this outputs to control component behavior
   */
  @Output() outSelectGoalsEvent = new EventEmitter<any>();
  @Output() outDeselectGoalsEvent = new EventEmitter<any>();
  @Output() outSelectTagsEvent = new EventEmitter<any>();
  @Output() outDeselectTagsEvent = new EventEmitter<any>();
  @Output() outSelectAdminUsersEvent = new EventEmitter<any>();
  @Output() outDeselectAdminUsersEvent = new EventEmitter<any>();
  @Output() outSelectScopesEvent = new EventEmitter<any>();
  @Output() outDeselectScopesEvent = new EventEmitter<any>();
  @Output() outApplyFiltersEvent = new EventEmitter<any>();

  /**
   * lists for filter components elements
   */
  goals: Goal[] = [];
  tags: Object[] = [];
  scopes: Scope[] = [];
  tagsByGoal: Tag[] = [];
  adminUsers: AdminUser[] = [];
  readyClass = 'hidden';

  /**
   * styles for generate button
   */
  generateButtonStatus = '';
  generateButtonClass = 'generate';

  /**
   * filters component
   */
  adminUsersDropdownSettings = {};
  scopesDropdownSettings = {};
  goalsDropdownSettings = {};
  tagsDropdownSettings = {};

  ngSelectedAdminUsers: Object[] = [];
  ngSelectedScopes: Object[] = [];
  ngSelectedGoals: Object[] = [];
  ngSelectedTags: Object[] = [];

  /**
   * dom elements
   */
  @ViewChild('multiSelectGoals') goalsSelector: any;

  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private adminUsersService: AdminUsersService,
    private messageService: MessageService,
    private scopesService: ScopesService,
    private translate: TranslateService,
    private goalsService: GoalsService,
    private tagsService: TagsService,
    private utils: Utils,
  ) { }

  ngOnInit() {
    this.loadInitialData();

    this.goalsDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 30,
      allowSearchFilter: true,
      enableCheckAll: false,
    };

    this.tagsDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 30,
      allowSearchFilter: true,
      enableCheckAll: false,
    };

    this.adminUsersDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 30,
      allowSearchFilter: true,
      enableCheckAll: false,
    };

    this.scopesDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 30,
      allowSearchFilter: true,
      enableCheckAll: false,
    };
  }

  /**
   * load initial data in each selector element
   */
  loadInitialData() {
    this.spinnerService.show();

    const promise1 = this.goalsService.setGoals();

    const promise2 = this.adminUsersService.setAdminUsers();

    const promise3 = this.scopesService.setScopes([]);

    Promise.all([promise1, promise2, promise3])
    .then(
      (values) => {
        const goals = values[0];
        const adminUsers = values[1];
        const scopes = values[2];

        this.getTagsFromGoalsResponse(goals);
        this.goals = this.getItemsFromObject(goals);
        this.adminUsers = this.getAdminUsersToDisplay(adminUsers);
        this.scopes = scopes.sort(function(a, b) {
          return a.name.localeCompare(b.name);
        });
        this.readyClass = '';

        this.spinnerService.hide();
      },
      (err) => {
        this.spinnerService.hide();

        const errorMessage = this.utils.messageManager(err.error.status, err.error.message);
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('messages.common.error'),
          detail: this.translate.instant(errorMessage)
        });
      }
    );
  }

  /**
   * Create array {id, name} to show
   * @param adminsUsers adminsUsers list
   */
  getAdminUsersToDisplay(adminsUsers) {
    const adminUsersToDisplay = adminsUsers.map(adminUser => {
      return {
        id: adminUser.id,
        name: `${adminUser.firstName} ${adminUser.lastName}`
      };
    });
    return this.getItemsFromObject(adminUsersToDisplay);
  }

  /**
   * set array of objects to show in each selector
   * { id, name } - value to show and value to select
   * @param objectList - api request
   */
  getItemsFromObject(objectList) {
    return objectList.map(objectItem => {
      return {
        id: objectItem.id,
        name: objectItem.name
      };
    });
  }

  /**
   * get tags from goals
   * set global array tags
   */
  getTagsFromGoalsResponse(goalsResponse) {
    const tagsByGoal = [];
    goalsResponse.map(goal => {
      goal.tags.map(tag => {
        this.addTagByGoalId(tag, goal.id);
        if (tag !== '' && !tagsByGoal.includes(tag)) {
          tagsByGoal.push(tag);
        }
      });
    });
    this.tagsService.setTags(this.tagsByGoal);
    this.tags = tagsByGoal.map(tag => {
      return {
        id: tag,
        name: tag
      };
    });
  }

  /**
   * define an object to save in store
   * Object has a relations between tags and the goals ids where this tags are declared
   * @param tagName - tag to search
   * @param goalId - goal where tag is declared
   */
  addTagByGoalId(tagName, goalId) {
    if (tagName === '') { return; }
    const foundIndex = this.tagsByGoal.findIndex(itemTag => itemTag['name'] === tagName);
    if (foundIndex === -1) {
      this.tagsByGoal.push(new Tag(this.tagsByGoal.length, tagName, [goalId]));
    } else {
      const indexGoal = this.tagsByGoal[foundIndex]['goals'].findIndex( itemGoalId => itemGoalId === goalId);
      if (indexGoal === -1) {
        this.tagsByGoal[foundIndex]['goals'].push(goalId);
      }
    }
  }

  /**
   * triggered when select goal
   * @param event
   */
  eventSelectionGoals(event) {
    this.outSelectGoalsEvent.emit(event);
  }

  /**
   * triggered when deselect goal
   * @param event
   */
  eventDeselectionGoals(event) {
    this.outDeselectGoalsEvent.emit(event);
  }

  /**
   * triggered when select tags
   * @param event
   */
  eventSelectionTags(event) {
    this.outSelectTagsEvent.emit(event);
  }

  /**
   * triggered when deselect tags
   * @param event
   */
  eventDeselectionTags(event) {
    this.outDeselectTagsEvent.emit(event);
  }

  /**
   * triggered when select admin users
   * @param event
   */
  eventSelectionAdminUsers(event) {
    this.outSelectAdminUsersEvent.emit(event);
  }

  /**
   * triggered when deselect admin users
   * @param event
   */
  eventDeselectionAdminUsers(event) {
    this.outDeselectAdminUsersEvent.emit(event);
  }

  /**
   * triggered when select scopes
   * @param event
   */
  eventSelectionScopes(event) {
    this.outSelectScopesEvent.emit(event);
  }

  /**
   * triggered when deselect scopes
   * @param event
   */
  eventDeselectionScopes(event) {
    this.outDeselectScopesEvent.emit(event);
  }

  /**
   * triggered when apply filters button
   * @param event
   */
  eventApplyFilters(event) {
    this.outApplyFiltersEvent.emit(event);
  }

}
