import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';

@Injectable()
export class Utils {
  constructor (
    private router: Router
  ) {}

  /**
   * create header for api requests
   * add Authorization token
   * other values for IE
   * @param parameterToken - if parameter exist, set localstorage
   */
  validateRoute() {
    const localStorageToken = String(localStorage.bertToken);

    if (localStorageToken === 'undefined') {
      this.router.navigate(['/authorization']);
    }
  }

  /**
   * Function to control the error messages according to
   * error code and error message from BE
   * @param status error code
   * @param errorMessage error message from BE
   */
  messageManager(status, errorMessage): string {
    let message = '';
    switch (status) {
      case 401:
        message = 'messages.error.401';
        break;
      case 404:
        if (errorMessage = 'API Key for this customer was not found') {
          message = 'messages.error.404-1';
        }
        break;
      default:
        message = 'messages.common.general-error';
    }
    return message;
  }

  handleActiveFilterStyle(inputFilters, renderer) {
    inputFilters.forEach(filter => {
      if (filter.nativeElement.value !== '') {
        renderer.addClass(filter.nativeElement, 'active-filter');
      } else {
      if (filter.nativeElement.classList.contains('active-filter')) {
        renderer.removeClass(filter.nativeElement, 'active-filter');
        }
      }
    });
  }
}
