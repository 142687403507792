import { ID } from '@datorama/akita';

export class Student {
  id: ID;
  name: string;

  constructor(id: ID, name: string) {
    this.id = id;
    this.name = name;
  }
}
