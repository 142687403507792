import { Component, OnInit, ViewChild } from '@angular/core';
import {Ng4LoadingSpinnerService} from "ng4-loading-spinner";
import {StudyPathsQuery, StudyPathsService} from "../../state/study-paths";
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {UsersQuery} from "../../state/users";
import {TagsQuery} from "../../state/tags";
import {Utils} from "../../shared/utils";
import { ShareStudentsFiltersData } from "../share-students-filters-data";
import { DateSelection } from "../../shared/date-filters/models/date-selection";
import {Goal} from "../../state/goals";
import {Scope} from "../../state/scopes";


@Component({
  selector: 'app-advanced-reporting-filters',
  templateUrl: './advanced-reporting-filters.component.html',
  styleUrls: ['../../consolidated-students-scores/consolidated-students-scores.component.scss']
})

export class AdvancedReportingFiltersComponent implements OnInit {

  /**
   * paths for breadcrumb component
   */
  pathsToShow = [0, 3];

  /**
   * definitions for global variables
   */
    // filters container
  isFilterSectionOpen = true;
  collapseButtonLabel = 'filters.hide-filters';
  collapseButtonIcon = 'keyboard_arrow_up';

  // study path table
  showStudyPathTable = false;
  filterParametersStudyPaths = '';

  // filters in header
  studyPathFilterHeaders = [];

  // pagination
  studyPathPaginationPage = 0;
  studyPathActivityRows = 10;

  // sorting
  studyPathSorting = {};

  // report table
  reportRow = {};

  // selected values in the filter component
  selectedGoals: Goal[] = [];
  selectedScope: Scope = null;

  showStudentTable = false;
  row: object[] = [];

  /**
   * dom elements
   */
  @ViewChild('filtersContainer') filtersContainer: any;
  @ViewChild('collapseMessage') collapseMessageContainer: any;
  @ViewChild('filterComponentContainer') filterComponentContainer: any;
  @ViewChild('filterTableContainer') filterTableContainer: any;
  @ViewChild('buttonsContainer') buttonsContainer: any;

  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private studyPathsService: StudyPathsService,
    private studyPathsQuery: StudyPathsQuery,
    private messageService: MessageService,
    private translate: TranslateService,
    private usersQuery: UsersQuery,
    private tagsQuery: TagsQuery,
    private utils: Utils,
    private sharedFilters: ShareStudentsFiltersData
  ) {
    this.utils.validateRoute();
  }

  ngOnInit(): void {
    let dateSelection = new DateSelection();
    dateSelection.setDefaultValues();
    this.sharedFilters.setSelectedDates(dateSelection);
    this.sharedFilters.setFiltered(false);
  }

  // UI EVENTS

  /**
   * when user select goals from selector component
   * @param goals - it has a selected item
   */
  eventSelectGoals(goals) {
    this.selectedGoals = goals;
    this.sharedFilters.changeSelectedGoals(goals);
    this.generateFiltersForStudyPaths();
  }

  /**
   * when user select scopes from selector component
   * @param scope - the selected scope
   */
  eventSelectScopes(scope:Scope) {
    this.selectedScope = scope;
    this.generateFiltersForStudyPaths();
  }

  /**
   * triggered with the show/hide button in the filters component
   */
  eventToggleFilterContainer() {
    const filterContainerElement = this.filtersContainer.nativeElement;
    const collapseMessageElement = this.collapseMessageContainer.nativeElement;
    const filterComponentContainerElement = this.filterComponentContainer.nativeElement;
    const filterTableContainerElement = this.filterTableContainer !== undefined ? this.filterTableContainer.nativeElement : undefined;
    const buttonsContainerElement = this.buttonsContainer !== undefined ? this.buttonsContainer.nativeElement : undefined;

    if (this.isFilterSectionOpen) {
      this.collapseButtonIcon = 'keyboard_arrow_down';
      this.collapseButtonLabel = 'filters.show-filters';
      collapseMessageElement.classList.add('collapse');
      filterContainerElement.classList.add('collapse');
      filterComponentContainerElement.classList.add('collapse');
      if (filterTableContainerElement !== undefined) { filterTableContainerElement.classList.add('collapse'); }
      if (buttonsContainerElement) { buttonsContainerElement.classList.add('colastNamellapse'); }
    } else {
      this.collapseButtonIcon = 'keyboard_arrow_up';
      this.collapseButtonLabel = 'filters.hide-filters';
      collapseMessageElement.classList.remove('collapse');
      filterContainerElement.classList.remove('collapse');
      filterComponentContainerElement.classList.remove('collapse');
      if (filterTableContainerElement !== undefined) { filterTableContainerElement.classList.remove('collapse'); }
      if (buttonsContainerElement) { buttonsContainerElement.classList.remove('collapse'); }
    }
    this.isFilterSectionOpen = !this.isFilterSectionOpen;
  }

  /**
   * generate parameters according to the selection
   * in the filters section
   */
  generateFiltersForStudyPaths() {
    let filterExist = false;
    let goalsIds = '';
    let scopesIds = '';
    let filtersHeaders = '';
    let previousFiltersHeaders = '';
    let sorting = '';

    this.showStudyPathTable = false;
    this.filterParametersStudyPaths = `?size=${this.studyPathActivityRows}&page=${this.studyPathPaginationPage}`;

    if (this.selectedGoals.length > 0) {
      filterExist = true;
      goalsIds = `&goals=${this.selectedGoals.map(goal => goal.id).join()}`;
    }
    if (this.selectedScope) {
      filterExist = true;
      scopesIds = `&scopes=${this.selectedScope}`;
    }
    this.studyPathFilterHeaders.forEach(filterHeader => {
      if (filterHeader['value'] !== '') {
        filterExist = true;
        previousFiltersHeaders = filtersHeaders === '' ? '&filters=' : ',';

        filtersHeaders += `${previousFiltersHeaders}${filterHeader['field']}=${filterHeader['value']}`;
      }
    });
    if (this.studyPathSorting['column'] !== undefined && this.studyPathSorting['value'] !== undefined) {
      filterExist = true;
      sorting = `&sort=${this.studyPathSorting['column']},${this.studyPathSorting['value']}`;
    }
    if (filterExist) {
      this.filterParametersStudyPaths = this.filterParametersStudyPaths.concat(
        `${filtersHeaders}${sorting}${goalsIds}${scopesIds}`
      );
    }
  }

  /**
   * triggered with apply filters button
   */
  eventApplyFilters() {
    this.sharedFilters.changeSelectedGoals(this.selectedGoals);
    this.sharedFilters.changeSelectedScope(this.selectedScope);
  }
}
