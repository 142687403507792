import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { PaginatorModule } from 'primeng/paginator';
import { Ng4LoadingSpinnerModule  } from 'ng4-loading-spinner';

/**
 * custom imports
 */
import { SharedModules } from '../shared/shared.module';
import { ConsolidatedStudentsScoresRoutingModule } from './consolidated-students-scores-routing.module';
import { ConsolidatedStudentsScoresComponent } from './consolidated-students-scores.component';
import { MaterialModule } from '../material.module';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { EmailService } from '../services/email.service';

@NgModule({
  imports: [
    Ng4LoadingSpinnerModule.forRoot(),
    CommonModule,
    ConsolidatedStudentsScoresRoutingModule,
    TranslateModule.forChild({}),
    FormsModule,
    MaterialModule,
    TableModule,
    TooltipModule,
    PaginatorModule,
    ToastModule,
    // Custom modules
    SharedModules
  ],
  exports: [
    ConsolidatedStudentsScoresComponent
  ],
  declarations: [
    ConsolidatedStudentsScoresComponent,
  ],
  providers: [
    MessageService,
    EmailService
  ]
})
export class ConsolidatedStudentsScoresModule { }
