import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

/**
 * model
 */
import { Student } from './students.model';

export interface StudentsState extends EntityState<Student> {}

/**
 * initial state for students
 */
export function createInitialState(): StudentsState {
  return {
    general: {
      total: ''
    }
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'students' })
export class StudentsStore extends EntityStore<StudentsState, Student> {
  constructor() {
    super(createInitialState());
  }

  /**
   * update general state
   * @param {string} total
   */
  updateStateGeneral(total= '') {
    this.updateRoot( { general: { total } } );
  }
}
