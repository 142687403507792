import { ID } from '@datorama/akita';

import { Deserializable } from '../../shared/models/deserializable.model';

export class Theme implements Deserializable {
  id: ID;
  name: string;
  logoUrl: string;

  constructor(id, name, logoUrl) {
    this.id = id;
    this.name = name;
    this.logoUrl = logoUrl;
  }

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
