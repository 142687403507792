import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { PathStore, PathState } from './path.store';
import { Path } from './path.model';

@Injectable({
  providedIn: 'root'
})
export class PathQuery extends QueryEntity<PathState, Path> {
  // selectPath$ = this.select<Path>(state => state.path);

  constructor(protected pathsStore: PathStore) {
    super(pathsStore);
  }
}
