import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

/**
 * model
 */
import { Theme } from './themes.model';

export interface ThemeState extends EntityState<Theme> {
  ui: {
    logoUrl: string
  };
}

/**
 * initial state for users
 */
export function createInitialState(): ThemeState {
  return {
    ui: {
      logoUrl: ''
    }
  };
}
@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'theme' })
export class ThemesStore extends EntityStore<ThemeState, Theme> {
  constructor() {
    super(createInitialState());
  }

  /**
   * update state for ui store
   * @param logoUrl
   */
  updateStateUI(logoUrl= '') {
    this.updateRoot( { ui: { logoUrl } } );
  }
}
