import {Component, ElementRef, OnInit, QueryList, Renderer2, ViewChild, ViewChildren} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import {debounceTime, tap} from 'rxjs/operators';

/**
 * third party components
 */
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MessageService } from 'primeng/api';
/**
 * shared
 */
import { Utils } from '../shared/utils';
/**
 * services
 */
import { ConsolidatedStudentsScoresService } from '../services/consolidated-students-scores.service';
/**
 * modal
 */
import { SendEmailComponent } from '../shared/modals/send-email/send-email.component';
/**
 * models
 */
import { TableColumn } from '../shared/models/table/Table.model';
/**
 * state
 */
import { StudyPathsQuery, StudyPathsService } from '../state/study-paths';
import { TagsQuery } from '../state/tags';
import { UsersQuery } from '../state/users';

@Component({
  selector: 'app-consolidated-students-scores',
  templateUrl: './consolidated-students-scores.component.html',
  styleUrls: ['./consolidated-students-scores.component.scss']
})

export class ConsolidatedStudentsScoresComponent implements OnInit {

  /**
   * paths for breadcrum component
   */
  pathsToShow = [0, 1];

  /**
   * definitions for global variables
   */
  // filters container
  isFilterSectionOpen = true;
  collapseButtonLabel = 'filters.hide-filters';
  collapseButtonIcon = 'keyboard_arrow_up';
  flagCollapseFilters = false;

  // study path table
  tableStudyPathsColumns: TableColumn[] = [];
  studyPathsData: object[] = [];
  selectedStudyPaths: Object[] = [];
  showStudyPathTable = false;
  filterParametersStudyPaths = '';
  // filters in header
  studyPathFilterHeaders = [];
  keyUpStudyPaths$ = new Subject<any>();
  // pagination
  studyPathPaginationPage = 0;
  studyPathPaginationLinkSize = 4;
  studyPathRows = 10;
  studyPathPaginationTotalRecords$: Observable<string>;
  // sorting
  studyPathSorting = {};
  studypathSortingValue = '';

  // report table
  tableScopesColumns: TableColumn[] = [];
  tableScopesColumnsCopy: TableColumn[] = [];
  tableGoalsColumns: TableColumn[] = [];
  tableGoalsColumnsCopy: TableColumn[] = [];
  consolidatedReportData: object[] = [];
  showReportTable = false;
  reportRow = {};
  // filters in header
  consolidatedReportFilterHeaders = [];
  filterParametersConsolidatedReport = '';
  filterParametersConsolidatedReportStructure = '';
  keyUpConsolidatedFilter$ = new Subject<any>();
  keyUpConsolidatedFirstName$ = new Subject<any>();
  keyUpConsolidatedLastName$ = new Subject<any>();
  keyUpConsolidatedEmail$ = new Subject<any>();
  // pagination
  consolidatedPaginationPage = 0;
  consolidatedPaginationLinkSize = 4;
  consolidatedRows = 10;
  consolidatedPaginationTotalRecords = 0;
  // sorting
  consolidatedSorting = {};
  consolidatedSortingValue = '';

  // send email
  filterParametersSendEmail = '';

  // selected values in the filter component
  selectedGoals: number[] = [];
  selectedTags: string[] = [];
  selectedGoalIdsByTags: number[] = [];
  selectedAdminUsers: number[] = [];
  selectedScopes: number[] = [];

  // user information
  userEmail = '';

  /**
   * dom elements
   */
  @ViewChild('filtersContainer') filtersContainer: any;
  @ViewChild('collapseMessage') collapseMessageContainer: any;
  @ViewChild('filterComponentContainer') filterComponentContainer: any;
  @ViewChild('filterTableContainer') filterTableContainer: any;
  @ViewChild('buttonsContainer') buttonsContainer: any;
  @ViewChildren('inputFilter') inputFilters: QueryList<ElementRef>;

  constructor(
    private consolidatedStudentsScoresService: ConsolidatedStudentsScoresService,
    private spinnerService: Ng4LoadingSpinnerService,
    private studyPathsService: StudyPathsService,
    private studyPathsQuery: StudyPathsQuery,
    private messageService: MessageService,
    private translate: TranslateService,
    private usersQuery: UsersQuery,
    private tagsQuery: TagsQuery,
    private dialog: MatDialog,
    private utils: Utils,
    private renderer: Renderer2
  ) {
    this.utils.validateRoute();
  }

  ngOnInit() {
    this.keyUpStudyPaths$.pipe(tap(() => this.utils.handleActiveFilterStyle(this.inputFilters, this.renderer)), debounceTime(1000)).subscribe(data => {
      return this.eventFilterStudyPathTable(data['value'], data['field']);
    });
    this.keyUpConsolidatedFilter$.pipe(tap(() => this.utils.handleActiveFilterStyle(this.inputFilters, this.renderer)), debounceTime(1000)).subscribe(data => {
      return this.eventFilterConsolidatedReportTable(data['value'], data['field']);
    });
    this.keyUpConsolidatedFirstName$.pipe(tap(() => this.utils.handleActiveFilterStyle(this.inputFilters, this.renderer)), debounceTime(1000)).subscribe(data => {
      return this.eventFilterConsolidatedReportTable(data['value'], data['field']);
    });
    this.keyUpConsolidatedLastName$.pipe(tap(() => this.utils.handleActiveFilterStyle(this.inputFilters, this.renderer)), debounceTime(1000)).subscribe(data => {
      return this.eventFilterConsolidatedReportTable(data['value'], data['field']);
    });
    this.keyUpConsolidatedEmail$.pipe(tap(() => this.utils.handleActiveFilterStyle(this.inputFilters, this.renderer)), debounceTime(1000)).subscribe(data => {
      return this.eventFilterConsolidatedReportTable(data['value'], data['field']);
    });

    this.usersQuery.selectEmail$.subscribe(email => {
      this.userEmail = email;
    });

    this.studyPathPaginationTotalRecords$ = this.studyPathsQuery.selectTotal$;

    this.generateStudyPathsFixedColumns();
    this.generateConsolidatedReportFixedColumns();

    this.studyPathFilterHeaders.push({ field: 'scopeName', value: '' });
    this.studyPathFilterHeaders.push({ field: 'goalName', value: '' });
  }

  // UI EVENTS

  /**
   * when user select goals from selecter component
   * @param event - it has a selected item
   */
  eventSelectGoals(event) {
    this.selectedGoals.push(event['id']);
    this.generateFiltersForStudyPaths();
  }

  /**
   * when user deselect goals from selecter component
   * @param event - it has a selected item
   */
  eventDeselectGoals(event) {
    const index = this.selectedGoals.indexOf(event['id']);
    if (index > -1) {
      this.selectedGoals.splice(index, 1);
    }
    this.generateFiltersForStudyPaths();
  }

  /**
   * when user select tags from selecter component
   * @param event - it has a selected item
   */
  eventSelectTags(event) {
    this.selectedTags.push(event);
    this.setGoalIdsByTags();
  }

  /**
   * when user deselect tags from selecter component
   * @param event - it has a selected item
   */
  eventDeselectTags(event) {
    const index = this.selectedTags.indexOf(event);
    if (index > -1) {
      this.selectedTags.splice(index, 1);
    }
    this.setGoalIdsByTags();
  }

  /**
   * when user select adminUsers from selecter component
   * @param event - it has a selected item
   */
  eventSelectAdminUsers(event) {
    this.selectedAdminUsers.push(event['id']);
    this.generateFiltersForStudyPaths();
  }

  /**
   * when user deselect adminusers from selecter component
   * @param event - it has a selected item
   */
  eventDeselectAdminUsers(event) {
    const index = this.selectedAdminUsers.indexOf(event['id']);
    if (index > -1) {
      this.selectedAdminUsers.splice(index, 1);
    }
    this.generateFiltersForStudyPaths();
  }

  /**
   * when user select scopes from selecter component
   * @param event - it has a selected item
   */
  eventSelectScopes(event) {
    this.selectedScopes.push(event['id']);
    this.generateFiltersForStudyPaths();
  }

  /**
   * when user deselect scopes from selecter component
   * @param event - it has a selected item
   */
  eventDeselectScopes(event) {
    const index = this.selectedScopes.indexOf(event['id']);
    if (index > -1) {
      this.selectedScopes.splice(index, 1);
    }
    this.generateFiltersForStudyPaths();
  }

  /**
   * triggered with apply filters button
   * @param $event
   */
  eventApplyFilters($event) {
    this.generateFiltersForStudyPaths();
    this.applyFiltersForStudyPaths();
  }

  /**
   * selection in the studyPath table
   * @param event
   */
  eventStudyPathSelect($event, selection) {
    this.showReportTable = false;
  }

  /**
   * selection all in the studyPath table
   * @param event
   */
  eventStudyPathSelectAll($event, selection) {
    this.showReportTable = false;
  }

  /**
   * filter in headers, studyPath table
   * @param value
   * @param field
   */
  eventFilterStudyPathTable(value, field) {
    this.studyPathFilterHeaders.map(filterHeadder => {
      if (filterHeadder['field'] === field) {
        filterHeadder['value'] = value;
      }
    });
    this.generateFiltersForStudyPaths();
    this.applyFiltersForStudyPaths();
  }

  /**
   * filter in headers, studyPath table
   * @param value
   * @param field
   */
  eventFilterConsolidatedReportTable(value, field) {
    const filteredRow = this.consolidatedReportFilterHeaders.filter(objRow => {
      return objRow['field'] === field;
    });

    if (filteredRow.length === 0) {
      this.consolidatedReportFilterHeaders.push({ field, value });
    } else {
      this.consolidatedReportFilterHeaders.map(filterHeader => {
        if (filterHeader['field'] === field) {
          filterHeader['value'] = value;
        }
      });
    }

    this.generateFiltersForConsolidatedReport();
    this.generateReport();
  }

  /**
   * filter in headers, studyPath table
   * @param value
   * @param field
   */
  eventFilterScoreConsolidatedReportTable(value, field) {
    const filteredRow = this.consolidatedReportFilterHeaders.filter(objRow => {
      return objRow['field'] === field;
    });

    if (filteredRow.length === 0) {
      this.consolidatedReportFilterHeaders.push({ field, value });
    } else {
      this.consolidatedReportFilterHeaders.map(filterHeader => {
        if (filterHeader['field'] === field) {
          filterHeader['value'] = value;
        }
      });
    }

    this.generateFiltersForConsolidatedReport();
    this.generateReport();
  }

  /**
   * paginate in studyPath table
   * @param event
   */
  eventPaginateStudyPathTable(event) {
    this.studyPathPaginationPage = event.page;
    this.studyPathRows = event.rows;
    this.generateFiltersForStudyPaths();
    this.applyFiltersForStudyPaths();
  }

  /**
   * triggered with generate button
   * @param $event
   */
  eventGenerateReport($event) {
    this.showReportTable = false;
    if (this.selectedStudyPaths === undefined || this.selectedStudyPaths.length <= 0) {
      this.messageService.add({
        severity: 'warn',
        summary: this.translate.instant('messages.common.warning'),
        detail: this.translate.instant('messages.consolidated-ss.no-studypath-selection')
      });
      return;
    }
    this.generateFiltersForConsolidatedReport();

    this.generateReport(true);

  }

  /**
   * paginate in consolidated table
   * @param event
   */
  eventPaginateConsolidatedTable(event) {
    this.consolidatedPaginationPage = event.page;
    this.consolidatedRows = event.rows;
    this.generateFiltersForConsolidatedReport();
    this.generateReport();
  }

  /**
   * open modal to send emails
   */
  eventOpenSendEmailModal(): void {
    const listOfStudyPaths = this.selectedStudyPaths.map(studyPath => {
      return studyPath['id'];
    }).join();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.position = {
      top: '125px'
    };
    dialogConfig.data = {
      userEmail: this.userEmail,
      UrlApi: 'emails/consolidated',
      parameters: this.filterParametersSendEmail.concat(`&studyPath=${listOfStudyPaths}`)
    };
    const dialogRef = this.dialog.open(SendEmailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /**
   * trigger when sort column header in study path table
   * @param $event
   */
  eventSortStudyPathTable(event, col) {
    this.studypathSortingValue = this.studypathSortingValue === 'asc' ? 'desc' : 'asc';
    this.studyPathSorting = { column: col.field, value: this.studypathSortingValue };
    this.generateFiltersForStudyPaths();
    this.applyFiltersForStudyPaths();
  }

  /**
   * trigger when sort column header in consolidated table
   * @param $event
   */
  eventSortFirstNameConsolidatedTable(event) {
    this.consolidatedSortingValue = this.consolidatedSortingValue === 'asc' ? 'desc' : 'asc';
    this.consolidatedSorting = { column: 'firstName', value: this.consolidatedSortingValue };
    this.generateFiltersForConsolidatedReport();
    this.generateReport();
  }

  /**
   * trigger when sort column header in consolidated table
   * @param $event
   */
  eventSortLastNameConsolidatedTable(event) {
    this.consolidatedSortingValue = this.consolidatedSortingValue === 'asc' ? 'desc' : 'asc';
    this.consolidatedSorting = { column: 'lastName', value: this.consolidatedSortingValue };
    this.generateFiltersForConsolidatedReport();
    this.generateReport();
  }

  /**
   * trigger when sort column header in consolidated table
   * @param $event
   */
  eventSortEmailConsolidatedTable(event) {
    this.consolidatedSortingValue = this.consolidatedSortingValue === 'asc' ? 'desc' : 'asc';
    this.consolidatedSorting = { column: 'email', value: this.consolidatedSortingValue };
    this.generateFiltersForConsolidatedReport();
    this.generateReport();
  }

  /**
   * triggered with the show/hide button in the filters component
   */
  eventToggleFilterContainer() {
    const filterContainerElement = this.filtersContainer.nativeElement;
    const collapseMessageElement = this.collapseMessageContainer.nativeElement;
    const filterComponentContainerElement = this.filterComponentContainer.nativeElement;
    const filterTableContainerElement = this.filterTableContainer !== undefined ? this.filterTableContainer.nativeElement : undefined;
    const buttonsContainerElement = this.buttonsContainer !== undefined ? this.buttonsContainer.nativeElement : undefined;

    if (this.isFilterSectionOpen) {
      this.collapseButtonIcon = 'keyboard_arrow_down';
      this.collapseButtonLabel = 'filters.show-filters';
      collapseMessageElement.classList.add('collapse');
      filterContainerElement.classList.add('collapse');
      filterComponentContainerElement.classList.add('collapse');
      if (filterTableContainerElement !== undefined) { filterTableContainerElement.classList.add('collapse'); }
      if (buttonsContainerElement) { buttonsContainerElement.classList.add('colastNamellapse'); }
    } else {
      this.collapseButtonIcon = 'keyboard_arrow_up';
      this.collapseButtonLabel = 'filters.hide-filters';
      collapseMessageElement.classList.remove('collapse');
      filterContainerElement.classList.remove('collapse');
      filterComponentContainerElement.classList.remove('collapse');
      if (filterTableContainerElement !== undefined) { filterTableContainerElement.classList.remove('collapse'); }
      if (buttonsContainerElement) { buttonsContainerElement.classList.remove('collapse'); }
    }
    this.isFilterSectionOpen = !this.isFilterSectionOpen;
  }

  eventSortHeader(event, col) {
    const colIds = col['field'].split('-');

    switch (col.header) {
      case this.translate.instant('consolidated-ss.report.initial-score'):
        this.consolidatedSortingValue = this.consolidatedSortingValue === 'asc' ? 'desc' : 'asc';
        this.consolidatedSorting = { column: `scopeId=${colIds[1]},goalId=${colIds[2]},initialScore`, value: this.consolidatedSortingValue };
        break;
      case this.translate.instant('consolidated-ss.report.current-score'):
        this.consolidatedSortingValue = this.consolidatedSortingValue === 'asc' ? 'desc' : 'asc';
        this.consolidatedSorting = { column: `scopeId=${colIds[1]},goalId=${colIds[2]},currentScore`, value: this.consolidatedSortingValue };
        break;
    }
    this.generateFiltersForConsolidatedReport();
    this.generateReport();
  }

  // FUNCTIONALITY

  /**
   * define goals-ids according to selected tags
   */
  setGoalIdsByTags() {
    this.selectedGoalIdsByTags = [];
    this.tagsQuery.selectAll().subscribe(response => {
      this.selectedTags.map(selectedTagName => {
        const foundIndex = response.findIndex(tag => tag.name === selectedTagName);
        response[foundIndex].goals.map(goalId => {
          this.selectedGoalIdsByTags.push(Number(goalId));
        });
      });
      this.generateFiltersForStudyPaths();
    });
  }

  /**
   * generate parameters according to the selection
   * in the filters section
   */
  generateFiltersForStudyPaths() {
    let filterExist = false;
    let goalsIds = '';
    let scopesIds = '';
    let adminsUsersIds = '';
    let filtersHeaders = '';
    let previourFiltersHeaders = '';
    let sorting = '';

    this.showStudyPathTable = false;
    this.showReportTable = false;

    this.filterParametersStudyPaths = `?size=${this.studyPathRows}&page=${this.studyPathPaginationPage}`;

    if (this.selectedGoals.length > 0 || this.selectedGoalIdsByTags.length > 0) {
      filterExist = true;
      goalsIds = `&goals=${this.selectedGoals.concat(this.selectedGoalIdsByTags).join()}`;
    }
    if (this.selectedScopes.length > 0) {
      filterExist = true;
      scopesIds = `&scopes=${this.selectedScopes.join()}`;
    }
    if (this.selectedAdminUsers.length > 0) {
      filterExist = true;
      adminsUsersIds = `&admins=${this.selectedAdminUsers.join()}`;
    }
    this.studyPathFilterHeaders.map(filterHeader => {
      if (filterHeader['value'] !== '') {
        filterExist = true;
        previourFiltersHeaders = filtersHeaders === '' ? '&filters=' : ',';

        filtersHeaders += `${previourFiltersHeaders}${filterHeader['field']}=${filterHeader['value']}`;
      }
    });
    if (this.studyPathSorting['column'] !== undefined && this.studyPathSorting['value'] !== undefined) {
      filterExist = true;
      sorting = `&sort=${this.studyPathSorting['column']},${this.studyPathSorting['value']}`;
    }
    if (filterExist) {
      this.filterParametersStudyPaths = this.filterParametersStudyPaths.concat(
        `${filtersHeaders}${sorting}${goalsIds}${scopesIds}${adminsUsersIds}`
      );
    }
  }

  /**
   * Apply filters according to the selection
   * in the filters sectionlastName
   */
  applyFiltersForStudyPaths() {
    this.spinnerService.show();

    this.showStudyPathTable = true;
    this.showReportTable = false;
    this.selectedStudyPaths = [];

    this.studyPathsService.getStudyPaths(this.filterParametersStudyPaths).then(
      (existStudyPaths) => {
        this.spinnerService.hide();
        if (!existStudyPaths) {
          this.messageService.add({
            severity: 'warn',
            summary: this.translate.instant('messages.consolidated-ss.no-studypath-data-title'),
            detail: this.translate.instant('messages.consolidated-ss.no-studypath-data')
          });
        }
      },
      (err) => {
        this.spinnerService.hide();
        const errorMessage = this.utils.messageManager(err.error.status, err.error.message);
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('messages.common.error'),
          detail: this.translate.instant(errorMessage)
        });
      }
    );
    this.studyPathsQuery.selectAll().subscribe(allStudyPaths => {
      this.generateDataStudyPaths(allStudyPaths);
    });
  }

  /**
   * generate report structure
   * @param reportData
   */
  generateReportStructure(reportData) {
    this.consolidatedStudentsScoresService.getReportStructure(this.filterParametersConsolidatedReportStructure).then(
      (reportStructure) => {
        this.tableScopesColumns = [];
        this.tableScopesColumnsCopy = [];
        this.tableGoalsColumns = [];
        this.tableGoalsColumnsCopy = [];
        this.generateConsolidatedReportFixedColumns();
        this.generateDynamicColumns(reportStructure, reportData);
        this.generateDataReport(reportData);
      }
    );
  }

  /**
   * generate report according to the studypath selection
   * pagination, sort, filters
   */
  generateReport(createTable = false) {

    if (!this.validateStudyPathsSelection()) { return; }

    this.consolidatedStudentsScoresService.getReportData(this.filterParametersConsolidatedReport).then(
      (reportData) => {
        this.consolidatedPaginationTotalRecords = parseInt(reportData['total'], 10);

        delete reportData['total'];

        if (createTable) this.generateReportStructure(reportData);
        else this.generateDataReport(reportData);

        this.showReportTable = true;

        if (!this.flagCollapseFilters) {
          this.flagCollapseFilters = true;
          this.eventToggleFilterContainer();
        }
        this.spinnerService.hide();
      }
    );
  }

  /**
   * validate the study path selections
   * according to the selections send messages to the user
   */
  validateStudyPathsSelection() {
    if (this.selectedStudyPaths === undefined || this.selectedStudyPaths.length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: this.translate.instant(`messages.common.warning`),
        detail: this.translate.instant('messages.consolidated-ss.no-studypath-selection')
      });
      return false;
    } else if (this.selectedStudyPaths.length > 10) {
      this.messageService.add({
        severity: 'warn',
        summary: this.translate.instant(`messages.common.warning`),
        detail: this.translate.instant('messages.consolidated-ss.studypath-limit-max-selection')
      });
      return false;
    }
    return true;
  }

  /**
   * generate parameters according to the selection
   * in consolidated table
   */
  generateFiltersForConsolidatedReport() {
    let filtersHeaders = '';
    let previourFiltersHeaders = '';
    let sorting = '';

    if (this.selectedStudyPaths === undefined || this.selectedStudyPaths.length <= 0) { return; }

    let filterExist = false;
    let studyPathsIds = '';

    const listOfStudyPaths = this.selectedStudyPaths.map(studyPath => {
      return studyPath['id'];
    });

    this.filterParametersConsolidatedReport = `?size=${this.consolidatedRows}&page=${this.consolidatedPaginationPage}`;

    if (listOfStudyPaths.length > 0) {
      filterExist = true;
      studyPathsIds = `studyPath=${listOfStudyPaths}`;
    }
    this.consolidatedReportFilterHeaders.map(filterHeader => {
      if (filterHeader['value'] !== '') {
        filterExist = true;
        previourFiltersHeaders = filtersHeaders === '' ? '&filters=' : ',';

        switch (filterHeader['field']) {
          case 'firstName':
          case 'lastName':
            filtersHeaders += `${previourFiltersHeaders}${filterHeader['field']}=${filterHeader['value']}`;
            break;
          default:
            const filters = filterHeader['field'].split('-');
            const scopeId = filters[1];
            const goalId = filters[2];
            const filterField = this.getFilterField(filters[0]);
            const condition = this.getCondition(filterHeader['value']);

            if (condition !== '') {
              filtersHeaders += `${previourFiltersHeaders}${filterField}=scopeId:${scopeId}%2BgoalId:${goalId}%2B${condition}`;
            }
        }
      }
    });
    if (this.consolidatedSorting['column'] !== undefined && this.consolidatedSorting['value'] !== undefined) {
      filterExist = true;
      sorting = `sort=${this.consolidatedSorting['column']},${this.consolidatedSorting['value']}`;
    }

    if (filterExist) {
      this.filterParametersConsolidatedReport = this.filterParametersConsolidatedReport.concat(
        `${filtersHeaders}&${sorting}&${studyPathsIds}`
      );
      this.filterParametersConsolidatedReportStructure = `?${studyPathsIds}`;
      this.filterParametersSendEmail = this.filterParametersSendEmail.concat(
        `${filtersHeaders}&${sorting}`
      );
    }
  }


  getCondition(filterValue) {
    const condition = filterValue.slice(0, 1);
    switch (condition) {
      case '<':
        if (filterValue.length > 1) {
          return `lt:${filterValue.slice(1, filterValue.length)}`;
        } else {
          return '';
        }
      case '>':
        if (filterValue.length > 1) {
          return `gt:${filterValue.slice(1, filterValue.length)}`;
        } else {
          return '';
        }
      default:
        const range = filterValue.split(';');
        if (range.length > 1 &&
          range[0] !== '' &&
          range[1] !== '') {
          return `bt:${range[0]}:${range[1]}`;
        } else {
          return `eq:${filterValue}`;
        }
    }
  }
  getFilterField(filterField) {
    switch (filterField) {
      case 'is':
        return 'initialScore';
      case 'cs':
        return 'currentScore';
      default:
        return '';
    }
  }

  // GENERATE DATA AND TABLES

  /**
   * generate data to load studyPath table
   * according to response from api
   */
  generateDataStudyPaths(studyPathData) {
    this.studyPathsData = [];
    studyPathData.map(studyPath => {
      const studyPathRow = {
        id: studyPath.id,
        scopeId: studyPath.scopeId,
        scopeName: studyPath.scopeName,
        goalId: studyPath.goalId,
        goalName: studyPath.goalName
      };
      this.studyPathsData.push(studyPathRow);
    });
  }

  /**
   * generate data to load report table
   * according to response from api
   */
  generateDataReport(reportData) {
    this.consolidatedReportData = [];
    Object.values(reportData).map(student => {
      this.addStudentToDataReport(
        student['id'],
        student['firstName'],
        student['lastName'],
        student['email']
      );
      let index = 4;
      if (student['customFields'] !== undefined) {
        student['customFields'].map(customField => {
          index++;
          this.consolidatedReportData.map(reportRow => {
            if (reportRow['studentId'] === student['id']) {
              reportRow[customField['id']] = customField['value'];
            }
          });
        });
      }
      if (student['scopes'] !== undefined) {
        student['scopes'].map(scope => {
          scope['goals'].map(goal => {
            for (let i = index; i < this.tableGoalsColumns.length; i += 3) {
              const column = this.tableGoalsColumns[i];
              const rowIds = column['field'].split('-');
              if (rowIds.length < 2) { return; }
              if (rowIds[1] === scope['id'].toString() && rowIds[2] === goal['id'].toString()) {
                this.consolidatedReportData.map(reportRow => {
                  if (reportRow['studentId'] === student['id']) {
                    reportRow[`gn-${scope['id']}-${goal['id']}`] = goal['name'];
                    reportRow[`is-${scope['id']}-${goal['id']}`] = goal['initialScore'];
                    reportRow[`cs-${scope['id']}-${goal['id']}`] = goal['currentScore'];
                  }
                });
              }
            }
          });
        });
      }
    });
  }

  /**
   * add a record for each student in
   * the api response
   * @param studentId
   * @param studentFirstName
   * @param studentLastName
   * @param studentEmail
   */
  addStudentToDataReport(studentId, studentFirstName, studentLastName, studentEmail) {
    const filteredRow = this.consolidatedReportData.filter(objRow => {
      return objRow['studentId'] === studentId;
    });
    if (filteredRow.length === 0) {
      const newRow = { ...this.reportRow };
      newRow['studentId'] = studentId;
      newRow['firstName'] = studentFirstName;
      newRow['lastName'] = studentLastName;
      newRow['email'] = studentEmail;
      this.consolidatedReportData.push(newRow);
    }
  }

  /**
   * add fixed columns in the consolidated table
   */
  generateStudyPathsFixedColumns() {
    this.createTableColumn(
      this.tableStudyPathsColumns,
      new TableColumn('id', 'id', '', false, 'id hidden')
    );
    this.createTableColumn(
      this.tableStudyPathsColumns,
      new TableColumn('scopeId', 'scopeId', '', false, 'scopeId hidden')
    );
    this.createTableColumn(
      this.tableStudyPathsColumns,
      new TableColumn('scopeName', 'consolidated-ss.study-paths.scope', '', false, 'scopeName') // , true, true, 1, 2)
    );
    this.createTableColumn(
      this.tableStudyPathsColumns,
      new TableColumn('goalId', 'goalId', '', false, 'goalId hidden')
    );
    this.createTableColumn(
      this.tableStudyPathsColumns,
      new TableColumn('goalName', 'consolidated-ss.study-paths.goal', '', false, 'goalName')
    );
  }

  /**
   * add fixed columns in the consolidated table
   */
  generateConsolidatedReportFixedColumns() {
    this.createTableColumn(
      this.tableScopesColumns,
      new TableColumn('studentIdLabel', this.translate.instant('consolidated-ss.report.student-id'), '', false,
        'student-id-column column-header-label-top hidden')
    );

    this.createTableColumn(
      this.tableScopesColumns,
      new TableColumn('studentFirstNameLabel', this.translate.instant('consolidated-ss.report.student-first-name'), '', false,
        'student-first-name-column column-header-label-top')
    );

    this.createTableColumn(
      this.tableScopesColumns,
      new TableColumn('studentLastNameLabel', this.translate.instant('consolidated-ss.report.student-last-name'), '', false,
        'student-last-name-column column-header-label-top')
    );

    this.createTableColumn(
      this.tableScopesColumnsCopy,
      new TableColumn('studentIdLabel', this.translate.instant('consolidated-ss.report.student-id'), '', false,
        'student-id-column column-header-label-top hidden')
    );

    this.createTableColumn(
      this.tableScopesColumnsCopy,
      new TableColumn('studentFirstNameLabel', this.translate.instant('consolidated-ss.report.student-first-name'), '', false,
        'student-first-name-column column-header-label-top')
    );

    this.createTableColumn(
      this.tableScopesColumnsCopy,
      new TableColumn('studentLastNameLabel', this.translate.instant('consolidated-ss.report.student-last-name'), '', false,
        'student-last-name-column column-header-label-top')
    );

    this.createTableColumn(
      this.tableGoalsColumns,
      new TableColumn('studentId', '', '', true, 'student-id-column column-header-label-bottom hidden', false, false, 1, 1)
    );

    this.createTableColumn(
      this.tableGoalsColumns,
      new TableColumn('firstName', '', '', true, 'student-first-name-column column-header-label-bottom', false, false, 1, 1)
    );

    this.createTableColumn(
      this.tableGoalsColumns,
      new TableColumn('lastName', '', '', true, 'student-last-name-column column-header-label-bottom', false, false, 1, 1)
    );

    this.createTableColumn(
      this.tableGoalsColumnsCopy,
      new TableColumn('studentId', '', '', true, 'student-id-column column-header-label-bottom hidden', false, false, 1, 1)
    );

    this.createTableColumn(
      this.tableGoalsColumnsCopy,
      new TableColumn('firstName', '', '', true, 'student-first-name-column column-header-label-bottom', false, false, 1, 1)
    );

    this.createTableColumn(
      this.tableGoalsColumnsCopy,
      new TableColumn('lastName', '', '', true, 'student-last-name-column column-header-label-bottom', false, false, 1, 1)
    );

    this.createTableColumn(
      this.tableScopesColumns,
      new TableColumn('studentEmailLabel', this.translate.instant('consolidated-ss.report.student-email'),
        '', false, 'column-header-label-top')
    );
    this.createTableColumn(
      this.tableScopesColumnsCopy,
      new TableColumn('studentEmailLabel', this.translate.instant('consolidated-ss.report.student-email'),
        '', false, 'column-header-label-top')
    );

    this.createTableColumn(
      this.tableGoalsColumns,
      new TableColumn('email', '', '', true, 'column-header-label-bottom', false, false, 1, 1)
    );

    this.createTableColumn(
      this.tableGoalsColumnsCopy,
      new TableColumn('email', '', '', true, 'column-header-label-bottom', false, false, 1, 1)
    );
  }

  /**
   * generate body columns according to API response
   * @param reportStructure
   * @param reportData - api response
   */
  generateDynamicColumns(reportStructure, reportData) {
    let columnList: TableColumn[] = [];
    Object.values(reportData).map(student => {
      if (student['customFields'] !== undefined) {
        student['customFields'].map(customField => {
          this.addScopeColumn(columnList, customField['id'], customField['label'],
            'column-header-label-top',
            this.translate.instant('messages.consolidated-ss.custom-fields-tooltip'));
          this.createTableColumn(this.tableGoalsColumns,
            new TableColumn(customField['id'], '', '', true, 'custom-field-value column-header-label-bottom'));
          this.createTableColumn(this.tableGoalsColumnsCopy,
            new TableColumn(customField['id'], '', '', true, 'custom-field-value column-header-label-bottom'));
        });
      }
    });
    let scopeColumnsList: TableColumn[] = [];
    Object.values(reportStructure).map(scope => {
      this.addScopeColumn(scopeColumnsList, scope['id'], scope['name']);
      scope['goals'].map(goal => {
        this.verifyScopeColumnsColspan(scopeColumnsList, scope['id'], goal['id']);
        this.createTableColumn(this.tableGoalsColumns,
          new TableColumn(`gn-${scope['id']}-${goal['id']}`,
            this.translate.instant('consolidated-ss.report.goal-name'), '', true,
            'goal-name-column second-level-sticky', false, false, 1, 1));
        this.createTableColumn(this.tableGoalsColumns,
          new TableColumn(`is-${scope['id']}-${goal['id']}`,
            this.translate.instant('consolidated-ss.report.initial-score'), '', true,
            'second-level-sticky', true, true));
        this.createTableColumn(this.tableGoalsColumns,
          new TableColumn(`cs-${scope['id']}-${goal['id']}`,
            this.translate.instant('consolidated-ss.report.current-score'), '', true,
            'second-level-sticky', true, true));
        this.createTableColumn(this.tableGoalsColumnsCopy,
          new TableColumn(`gn-${scope['id']}-${goal['id']}`,
            this.translate.instant('consolidated-ss.report.goal-name'), '', true,
            'goal-name-column second-level-sticky', false, false, 1, 1));
        this.createTableColumn(this.tableGoalsColumnsCopy,
          new TableColumn(`is-${scope['id']}-${goal['id']}`,
            this.translate.instant('consolidated-ss.report.initial-score'), '', true,
            'second-level-sticky', true, true));
        this.createTableColumn(this.tableGoalsColumnsCopy,
          new TableColumn(`cs-${scope['id']}-${goal['id']}`,
            this.translate.instant('consolidated-ss.report.current-score'), '', true,
            'second-level-sticky', true, true));
      });
    });
    columnList = columnList.concat(scopeColumnsList);
    columnList.map(column => {
      this.createTableColumn(this.tableScopesColumns, column);
      this.createTableColumn(this.tableScopesColumnsCopy, column);
    });
  }

  /**
   * add column to scopeColumsList
   * this columns are for the report's header
   * @param scopesList - list of scope columns
   * @param scopeId
   * @param scopeName
   * @param className
   * @param tooltip
   */
  addScopeColumn(scopesList, scopeId, scopeName, className = '', tooltip = '') {
    const filteredRow = scopesList.filter(objRow => {
      return objRow['field'] === scopeId;
    });
    if (filteredRow.length === 0) {
      scopesList.push(new TableColumn(scopeId, scopeName, tooltip, false, className, false, false, 0));
    }
  }

  /**
   * increment +3 colspan in the associate scope column, according to the goal
   * @param scopeColumnsList
   * @param scopeId
   * @param goalId
   */
  verifyScopeColumnsColspan(scopeColumnsList, scopeId, goalId) {
    if (!this.tableGoalsColumns.some(e => e['field'] === `gn-${scopeId}-${goalId}`)) {
      scopeColumnsList.map(scopeColumn => {
        if (scopeColumn['field'] === scopeId) {
          scopeColumn.incrementColspan(3);
        }
      });
    }
  }


  /**
   * create a tableColumn with all the required properties
   * @param columnsList - columns list
   * @param consolidatedSSColumn - report column
   */
  createTableColumn(columnsList, consolidatedSSColumn: TableColumn) {
    const column = {
      field: consolidatedSSColumn.field,
      header: consolidatedSSColumn.header,
      filter: consolidatedSSColumn.filter,
      tooltip: consolidatedSSColumn.tooltip,
      sortable: consolidatedSSColumn.sortable,
      colspan: consolidatedSSColumn.colspan,
      rowspan: consolidatedSSColumn.rowspan,
      className: consolidatedSSColumn.className,
      filterMatchMode: 'contains'
    };
    if (!columnsList.some(e => e['field'] === column['field'])) {
      columnsList.push(column);
      if (consolidatedSSColumn.addToReportRow) { this.reportRow[consolidatedSSColumn.field] = ''; }
    }
  }
}
