import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  Renderer2,
  ViewChildren,
  QueryList, ElementRef
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import {debounceTime, tap} from 'rxjs/operators';

/**
 * third party components
 */
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MessageService } from 'primeng/api';
/**
 * shared
 */
import { Utils } from '../shared/utils';

/**
 * models
 */
import { TableColumn } from '../shared/models/table/Table.model';
/**
 * state
 */
import { TagsQuery } from '../state/tags';
import { UsersQuery } from '../state/users';
import { SessionSummaryComponent } from "./session-summary.component";

import { ShareStudentsFiltersData } from "./share-students-filters-data";
import { StudyPathData } from "./study-path-data";
import { ShareSessionDetailsData } from "./share-session-details-data";
import {SessionsQuery, SessionsService} from "../state/sessions";

import * as moment from 'moment';
import { Scope } from "../state/scopes";
import { Goal } from "../state/goals";
import { SendEmailComponent } from "../shared/modals/send-email/send-email.component";

@Component({
  selector: 'app-students_list',
  templateUrl: './students-list.component.html',
  styleUrls: ['../consolidated-students-scores/consolidated-students-scores.component.scss']
})

export class StudentsListComponent implements OnInit {

  readonly dateFormat = 'MMM Do YYYY';

  /**
   * paths for breadcrumb component
   */
  pathsToShow = [0, 3, 4];

  /**
   * definitions for global variables
   */
  // filters container
  isFilterSectionOpen = true;
  collapseButtonLabel = 'filters.hide-filters';
  collapseButtonIcon = 'keyboard_arrow_up';

  // students table
  tableStudentsColumns: TableColumn[] = [];
  studentsData: object[] = [];

  @Input()
  studentIds: any[] = [];

  @Output()
  loadStudentIds: EventEmitter<object[]> = new EventEmitter<object[]>();

  selectedStudents: Object[];
  showStudentTable = false;
  allStudentsSelected = false;
  filterParametersStudents = '';

  // student filters in header
  studentsFilterHeaders = [];
  keyUpFirstName$ = new Subject<any>();
  keyUpLastName$ = new Subject<any>();
  keyUpEmail$ = new Subject<any>();

  // student pagination
  studentsPaginationPage = 0;
  studentsPaginationLinkSize = 4;
  studentsRows = 10;
  studentsPaginationTotalRecords$: Observable<string>;

  // student sorting
  studentsSorting = {};
  studentsSortingValue = '';

  // report table
  showReportTable = false;
  reportRow = {};

  // filters in header
  studentActivityReportFilterHeaders = [];

  // selected values in the filter component
  selectedGoals: Goal[] = [];
  selectedScope: Scope;
  selectedGoalNames: string;

  // send email
  filterParametersSendEmail = '';

  // user information
  userEmail = '';

  // Header content
  dateRange: string;
  excludedDates: string;
  timeRange: string;

  /**
   * dom elements
   */
  @ViewChild('filtersContainer') filtersContainer: any;
  @ViewChild('collapseMessage') collapseMessageContainer: any;
  @ViewChild('filterComponentContainer') filterComponentContainer: any;
  @ViewChild('filterTableContainer') filterTableContainer: any;
  @ViewChild('buttonsContainer') buttonsContainer: any;
  @ViewChild(SessionSummaryComponent) sessionSummaryComponent: SessionSummaryComponent;
  @ViewChildren('inputFilter') inputFilters: QueryList<ElementRef>;

  constructor(
    private sessionsService: SessionsService,
    private sessionsQuery: SessionsQuery,
    private spinnerService: Ng4LoadingSpinnerService,
    private messageService: MessageService,
    private translate: TranslateService,
    private usersQuery: UsersQuery,
    private tagsQuery: TagsQuery,
    private dialog: MatDialog,
    private utils: Utils,
    private sharedFilters: ShareStudentsFiltersData,
    private studyPathData: StudyPathData,
    private shareSessionDetails: ShareSessionDetailsData,
    private renderer: Renderer2
  ) {
    this.utils.validateRoute();
  }

  ngOnInit() {
    this.sharedFilters.currentSelectedGoals.subscribe(selectedGoals => {
      this.selectedGoals = selectedGoals;
      this.selectedGoalNames = selectedGoals.map(goal => goal.name).join();
    });
    this.sharedFilters.currentSelectedScope.subscribe(selectedScope => (this.selectedScope = selectedScope));

    this.studyPathData.setSelectedGoals(this.selectedGoals);
    this.studyPathData.setSelectedScope(this.selectedScope);

    this.keyUpFirstName$.pipe(tap(() => this.utils.handleActiveFilterStyle(this.inputFilters, this.renderer)), debounceTime(1000)).subscribe(data => {
      this.eventFilterStudentsTable(data['value'], data['field']);
    });
    this.keyUpLastName$.pipe(tap(() => this.utils.handleActiveFilterStyle(this.inputFilters, this.renderer)), debounceTime(1000)).subscribe(data => {
      this.eventFilterStudentsTable(data['value'], data['field']);
    });
    this.keyUpEmail$.pipe(tap(() => this.utils.handleActiveFilterStyle(this.inputFilters, this.renderer)), debounceTime(1000)).subscribe(data => {
      this.eventFilterStudentsTable(data['value'], data['field']);
    });

    this.studentsPaginationTotalRecords$ = this.sessionsQuery.selectTotal$;

    this.generateStudentTableFixedColumns();
    this.studentsFilterHeaders.push({ field: 'firstName', value: '' });
    this.studentsFilterHeaders.push({ field: 'lastName', value: '' });
    this.studentsFilterHeaders.push({ field: 'email', value: '' });

    this.usersQuery.selectEmail$.subscribe(email => {
      this.userEmail = email;
    });

    if (this.sharedFilters.isFiltered()) {
      this.eventSelectDates();
    }

  }

  // UI EVENTS

  /**
   * triggered with apply filters button
   */
  eventApplyFilters() {
    this.generateFiltersForStudents();
    this.applyFiltersForStudents();
    this.sharedFilters.setFiltered(true);
  }

  // UI EVENTS
  /**
   * selection all in the student table
   */
  eventStudentSelectAll() {
    this.showReportTable = false;
  }

  /**
   * trigger when sort column header in study path table
   * @param event
   * @param col
   */
  eventSortStudentsTable(event, col) {
    this.studentsSortingValue = this.studentsSortingValue === 'asc' ? 'desc' : 'asc';
    this.studentsSorting = { column: col.field, value: this.studentsSortingValue };
    this.generateFiltersForStudents();
    this.applyFiltersForStudents();
  }

  getStudentTable() {
    this.studentsSortingValue = this.studentsSortingValue === 'asc' ? 'desc' : 'asc';
    this.generateFiltersForStudents();
    this.applyFiltersForStudents();
  }

  /**
   * triggered with the show/hide button in the filters component
   */
  eventToggleFilterContainer() {
    const filterContainerElement = this.filtersContainer.nativeElement;
    const collapseMessageElement = this.collapseMessageContainer.nativeElement;
    const filterComponentContainerElement = this.filterComponentContainer.nativeElement;
    const filterTableContainerElement = this.filterTableContainer !== undefined ? this.filterTableContainer.nativeElement : undefined;
    const buttonsContainerElement = this.buttonsContainer !== undefined ? this.buttonsContainer.nativeElement : undefined;

    if (this.isFilterSectionOpen) {
      this.collapseButtonIcon = 'keyboard_arrow_down';
      this.collapseButtonLabel = 'filters.show-filters';
      collapseMessageElement.classList.add('collapse');
      filterContainerElement.classList.add('collapse');
      filterComponentContainerElement.classList.add('collapse');
      if (filterTableContainerElement !== undefined) { filterTableContainerElement.classList.add('collapse'); }
      if (buttonsContainerElement) { buttonsContainerElement.classList.add('colastNamellapse'); }
    } else {
      this.collapseButtonIcon = 'keyboard_arrow_up';
      this.collapseButtonLabel = 'filters.hide-filters';
      collapseMessageElement.classList.remove('collapse');
      filterContainerElement.classList.remove('collapse');
      filterComponentContainerElement.classList.remove('collapse');
      if (filterTableContainerElement !== undefined) { filterTableContainerElement.classList.remove('collapse'); }
      if (buttonsContainerElement) { buttonsContainerElement.classList.remove('collapse'); }
    }
    this.isFilterSectionOpen = !this.isFilterSectionOpen;
  }

  /**
   * create a tableColumn with all the required properties
   * @param columnsList - columns list
   * @param studentActivitySSColumn - report column
   */
  createTableColumn(columnsList, studentActivitySSColumn: TableColumn) {
    const column = {
      field: studentActivitySSColumn.field,
      header: studentActivitySSColumn.header,
      filter: studentActivitySSColumn.filter,
      tooltip: studentActivitySSColumn.tooltip,
      sortable: studentActivitySSColumn.sortable,
      colspan: studentActivitySSColumn.colspan,
      rowspan: studentActivitySSColumn.rowspan,
      className: studentActivitySSColumn.className,
      filterMatchMode: 'contains'
    };
    if (!columnsList.some(e => e['field'] === column['field'])) {
      columnsList.push(column);
      if (studentActivitySSColumn.addToReportRow) { this.reportRow[studentActivitySSColumn.field] = ''; }
    }
  }

  /**
   * add fixed columns in the students table
   */
  generateStudentTableFixedColumns() {
    this.createTableColumn(
      this.tableStudentsColumns,
      new TableColumn('id', 'student-activity.students.id', '', false, 'id hidden')
    );

    this.createTableColumn(
      this.tableStudentsColumns,
      new TableColumn('firstName', 'student-activity.students.firstName', '', false, 'firstName')
    );

    this.createTableColumn(
      this.tableStudentsColumns,
      new TableColumn('lastName', 'student-activity.students.lastName', '', false, 'lastName')
    );

    this.createTableColumn(
      this.tableStudentsColumns,
      new TableColumn('email', 'student-activity.students.email', '', false, 'email')
    );
  }

  getPaginationParams() {
    return `?size=${this.studentsRows}&page=${this.studentsPaginationPage}`;
  }

  /**
   * generate parameters according to the selection
   * in the filters section
   */
  generateFiltersForStudents() {
    let filterExist = false;
    let goalsIds = '';
    let dates:string;
    let scopesId = '';
    let filtersHeaders = '';
    let sorting = '';

    this.showStudentTable = false;
    this.showReportTable = false;
    this.filterParametersStudents = '';

    if (this.selectedGoals.length > 0) {
      filterExist = true;
      goalsIds = `&groups=${this.studyPathData.getSelectedGoalsIds()}`;
    }

    if (this.selectedScope != null) {
      filterExist = true;
      scopesId = `&assessment=${this.selectedScope.id}`;
    }

    if (this.studentsFilterHeaders[0]['value'] || this.studentsFilterHeaders[1]['value']
      || this.studentsFilterHeaders[2]['value']) {
      let existFilterHeader = false;
      this.studentsFilterHeaders.forEach(filterHeader => {
        if (filterHeader['value']) {
          filterExist = true;
          filtersHeaders += existFilterHeader ? `,${filterHeader['field']}=${filterHeader['value']}`
            : `${filterHeader['field']}=${filterHeader['value']}`;
          existFilterHeader = true;
        }
      });
      if (filtersHeaders) filtersHeaders = `&filters=${filtersHeaders}`;
    }
    if (this.studentsSorting['column'] !== undefined && this.studentsSorting['value'] !== undefined) {
      filterExist = true;
      sorting = `&sort=${this.studentsSorting['column']},${this.studentsSorting['value']}`;
    }

    dates = this.generateDateFiltersForStudents();
    if (dates) {
      filterExist = true;
    }

    if (filterExist) {
      this.filterParametersStudents = this.filterParametersStudents.concat(
        `${filtersHeaders}${sorting}${goalsIds}${scopesId}${dates}`
      );
    }
  }

  generateDateFiltersForStudents() {
    if (!this.sharedFilters.getSelectedDates()) {
      return '';
    }

    return this.sharedFilters.getSelectedDates().toQueryString();
  }

  /**
   * apply filters for students table
   */
  applyFiltersForStudents() {
    this.spinnerService.show();
    this.showStudentTable = true;
    const params = this.getPaginationParams() + this.filterParametersStudents;
    this.sessionSummaryComponent.eventHideSessionTable();
    this.sessionsService.getStudentsByStudyPath(params).then(
      (studentResponse) => {
        this.spinnerService.hide();
        if (!studentResponse) {
          this.messageService.add({
            severity: 'warn',
            summary: this.translate.instant('messages.students-list.no-students-data-title'),
            detail: this.translate.instant('messages.students-list.no-students-data')
          });
        } else {
          this.generateDataStudents(studentResponse.sessionData);
        }
      },
      (err) => {
        this.spinnerService.hide();
        const errorMessage = this.utils.messageManager(err.error.status, err.error.message);
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('messages.common.error'),
          detail: this.translate.instant(errorMessage)
        });
      }
    );
  }

  /**
   * generate data to load students table
   * according to response from api
   */
  generateDataStudents(studentData) {
    this.studentsData = [];
    studentData.map(student => {
      const studentRow = { id: student.id, firstName: student.firstName, lastName: student.lastName,
        email: student.email };
      this.studentsData.push(studentRow);
      this.studentIds.push(student.id)
      this.loadStudentIds.emit(this.studentIds);
    });
  }

  /**
   * filter in headers, studyPath table
   * @param value
   * @param field
   */
  eventFilterStudentsTable(value, field) {
    this.studentsFilterHeaders.forEach(filterHeader => {
      if (filterHeader['field'] === field) {
        filterHeader['value'] = value;
      }
    });
    this.generateFiltersForStudents();
    this.applyFiltersForStudents();

  }

  /**
   * paginate in students table
   * @param event
   */
  eventPaginateStudentsTable(event) {
    this.studentsPaginationPage = event.page;
    this.studentsRows = event.rows;
    this.generateFiltersForStudents();
    this.applyFiltersForStudents();
  }

  eventViewStudentSessionDetailTable(row) {
    this.showStudentTable = false;
    this.shareSessionDetails.changeStudentIdSelected(row['id']);
    this.shareSessionDetails.changeStudentName(row['firstName'] + " " + row['lastName']);
    this.shareSessionDetails.changeStudentEmail(row['email']);
  }

  eventViewSimpleStudentsTable() {
    this.getStudentTable();
  }

  eventViewStudentSessionsTable() {
    this.showStudentTable = false;
    this.sessionSummaryComponent.getSessionTable();
  }

  /**
   * open modal to send emails
   */
  eventOpenSendEmailModal(): void {
    if (this.selectedStudents === undefined || this.selectedStudents.length <= 0) {
      this.messageService.add({
        severity: 'warn',
        summary: this.translate.instant('messages.common.warning'),
        detail: this.translate.instant('messages.students-list.no-students-selection')
      });
      return;
    }

    const studentList = this.selectedStudents.map(student => {
      return student['id'];
    }).join();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.position = {
      top: '125px'
    };

    dialogConfig.data = {
      userEmail: this.userEmail,
      UrlApi: 'emails/csv/session',
      parameters: this.filterParametersStudents,
      selectionParameters: `&students=${studentList}`,
      showDataOptions: true,
    };
    const dialogRef = this.dialog.open(SendEmailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  eventSelectDates() {
    this.eventApplyFilters();
    this.getDateRange();
    this.getExcludedDates();
    this.getTimeRange();
  }

  getDateRange() {
    const selectedDates = this.sharedFilters.getSelectedDates();

    if (selectedDates && selectedDates.selected) {
      let startDate = moment(selectedDates.start).format(this.dateFormat);
      let endDate = moment(selectedDates.end).format(this.dateFormat);

      this.dateRange = `${startDate} to ${endDate}`;
      this.shareSessionDetails.changeDateRange(this.dateRange);
    }
  }

  getExcludedDates() {
    const selectedDates = this.sharedFilters.getSelectedDates();
    if (selectedDates.excludedDates.length > 0) {
      this.excludedDates = selectedDates.excludedDates.map(d => moment(d).format(this.dateFormat)).join(', ');
    }
    this.shareSessionDetails.changeExcludedDates(this.excludedDates);
  }

  getTimeRange() {
    this.timeRange = this.sharedFilters.getSelectedDates().timeRange;
    this.shareSessionDetails.changeTimeRange(this.timeRange);
  }
}
