import {Component, ElementRef, OnInit, QueryList, Renderer2, ViewChild, ViewChildren} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import {debounceTime, tap} from 'rxjs/operators';

/**
 * third party components
 */
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MessageService } from 'primeng/api';

/**
 * shared
 */
import { Utils } from '../shared/utils';

/**
 * services
 */
import { StudentProgressService } from '../services/student-progress.service';

/**
 * modal
 */
import { SendEmailComponent } from '../shared/modals/send-email/send-email.component';

/**
 * model
 */
import { TableColumn } from '../shared/models/table/Table.model';

/**
 * state
 */
import { StudentsQuery, StudentsService } from '../state/students';
import { TagsQuery } from '../state/tags';
import { UsersQuery } from '../state/users';

@Component({
  selector: 'app-student-progress',
  templateUrl: './student-progress.component.html',
  styleUrls: ['./student-progress.component.scss']
})
export class StudentProgressComponent implements OnInit {

  /**
   * paths for breadcrum component
   */
  pathsToShow = [0, 2];

  /**
   * definitions for global variables
   */
  // filters container
  isFilterSectionOpen = true;
  collapseButtonLabel = 'filters.hide-filters';
  collapseButtonIcon = 'keyboard_arrow_up';
  flagCollapseFilters = false;

  // students table
  tableStudentsColumns: TableColumn[] = [];
  studentsData: object[] = [];
  selectedStudents: Object[];
  showStudentTable = false;
  filterParametersStudents = '';
  // filters in header
  studentsFilterHeaders = [];
  keyUpFirstName$ = new Subject<any>();
  keyUpLastName$ = new Subject<any>();
  keyUpEmail$ = new Subject<any>();
  // pagination
  studentsPaginationPage = 0;
  studentsPaginationLinkSize = 4;
  studentsRows = 10;
  studentsPaginationTotalRecords$: Observable<string>;
  // sorting
  studentsSorting = {};
  studentsSortingValue = '';

  // report table
  showReportTable = false;
  tableReportColumns: TableColumn[] = [];
  reportData: object[] = [];
  reportRow = {};
  filterParametersReportTable = '';

  // send email
  filterParametersSendEmail = '';
  filterStudentId = '';

  // selected values in the filter component
  selectedGoalIdsByTags: number[] = [];
  selectedAdminUsers: number[] = [];
  selectedScopes: number[] = [];
  selectedGoals: number[] = [];
  selectedTags: string[] = [];

  // user information
  userEmail = '';
  studentId = '';

  /**
   * don elements
   */
  @ViewChild('filtersContainer') filtersContainer: any;
  @ViewChild('collapseMessage') collapseMessageContainer: any;
  @ViewChild('filterComponentContainer') filterComponentContainer: any;
  @ViewChild('filterTableContainer') filterTableContainer: any;
  @ViewChildren('inputFilter') inputFilters: QueryList<ElementRef>;

  constructor(
    private studentProgressService: StudentProgressService,
    private spinnerService: Ng4LoadingSpinnerService,
    private studentsService: StudentsService,
    private messageService: MessageService,
    private studentsQuery: StudentsQuery,
    private translate: TranslateService,
    private usersQuery: UsersQuery,
    private tagsQuery: TagsQuery,
    private dialog: MatDialog,
    private utils: Utils,
    private renderer: Renderer2
  ) {
    this.utils.validateRoute();
  }

  ngOnInit() {
    this.keyUpFirstName$.pipe(tap(() => this.utils.handleActiveFilterStyle(this.inputFilters, this.renderer)), debounceTime(1000)).subscribe(data => {
      this.eventFilterStudentsTable(data['value'], data['field']);
    });
    this.keyUpLastName$.pipe(tap(() => this.utils.handleActiveFilterStyle(this.inputFilters, this.renderer)), debounceTime(1000)).subscribe(data => {
      this.eventFilterStudentsTable(data['value'], data['field']);
    });
    this.keyUpEmail$.pipe(tap(() => this.utils.handleActiveFilterStyle(this.inputFilters, this.renderer)), debounceTime(1000)).subscribe(data => {
      this.eventFilterStudentsTable(data['value'], data['field']);
    });

    this.usersQuery.selectEmail$.subscribe(email => {
      this.userEmail = email;
    });

    this.studentsPaginationTotalRecords$ = this.studentsQuery.selectTotal$;

    this.generateStudentTableFixedColumns();
    this.generateReportTableFixedColumns();

    this.studentsFilterHeaders.push({ field: 'firstName', value: '' });
    this.studentsFilterHeaders.push({ field: 'lastName', value: '' });
    this.studentsFilterHeaders.push({ field: 'email', value: '' });
  }

  // UI EVENTS

  /**
   * when user select goals from selecter component
   * @param event - it has a list of the selected values
   */
  eventSelectGoals(event) {
    this.selectedGoals.push(event['id']);
    this.generateFiltersForStudents();
  }

  /**
   * when user deselect goals from selecter component
   * @param event - it has a list of the selected values
   */
  eventDeselectGoals(event) {
    const index = this.selectedGoals.indexOf(event['id']);
    if (index > -1) {
      this.selectedGoals.splice(index, 1);
    }
    this.generateFiltersForStudents();
  }

  /**
   * when user select tags from selecter component
   * @param event - it has a list of the selected values
   */
  eventSelectTags(event) {
    this.selectedTags.push(event);
    this.setGoalIdsByTags();
  }

  /**
   * when user deselect tags from selecter component
   * @param event - it has a selected item
   */
  eventDeselectTags(event) {
    const index = this.selectedTags.indexOf(event);
    if (index > -1) {
      this.selectedTags.splice(index, 1);
    }
    this.setGoalIdsByTags();
  }

  /**
   * when user select adminUsers from selecter component
   * @param event - it has a list of the selected values
   */
  eventSelectAdminUsers(event) {
    this.selectedAdminUsers.push(event['id']);
    this.generateFiltersForStudents();
  }

  /**
   * when user deselect adminusers from selecter component
   * @param event - it has a selected item
   */
  eventDeselectAdminUsers(event) {
    const index = this.selectedAdminUsers.indexOf(event['id']);
    if (index > -1) {
      this.selectedAdminUsers.splice(index, 1);
    }
    this.generateFiltersForStudents();
  }

  /**
   * when user select scopes from selecter component
   * @param event - it has a list of the selected values
   */
  eventSelectScopes(event) {
    this.selectedScopes.push(event['id']);
    this.generateFiltersForStudents();
  }

  /**
   * when user deselect scopes from selecter component
   * @param event - it has a selected item
   */
  eventDeselectScopes(event) {
    const index = this.selectedScopes.indexOf(event['id']);
    if (index > -1) {
      this.selectedScopes.splice(index, 1);
    }
    this.generateFiltersForStudents();
  }

  /**
   * triggered with apply filters button
   * @param $event
   */
  eventApplyFilters($event) {
    this.generateFiltersForStudents();
    this.applyFiltersForStudents();
  }

  /**
   * triggered with generate button
   * @param $event
   */
  eventGenerateReport(row) {
    this.studentId = row['id'];
    this.filterStudentId = `&students=${this.studentId}`;

    this.spinnerService.show();

    this.generateFiltersForStudentProgressReport();
    this.generateReport();
  }

  /**
   * triggered with the show/hide button in the filters component
   */
  eventToggleFilterContainer() {
    const filterContainerElement = this.filtersContainer.nativeElement;
    const collapseMessageElement = this.collapseMessageContainer.nativeElement;
    const filterComponentContainerElement = this.filterComponentContainer.nativeElement;
    const filterTableContainerElement = this.filterTableContainer !== undefined
      ? this.filterTableContainer.nativeElement : undefined;

    if (this.isFilterSectionOpen) {
      this.collapseButtonIcon = 'keyboard_arrow_down';
      this.collapseButtonLabel = 'filters.show-filters';
      collapseMessageElement.classList.add('collapse');
      filterContainerElement.classList.add('collapse');
      filterComponentContainerElement.classList.add('collapse');
      if (filterTableContainerElement !== undefined) { filterTableContainerElement.classList.add('collapse'); }
    } else {
      this.collapseButtonIcon = 'keyboard_arrow_up';
      this.collapseButtonLabel = 'filters.hide-filters';
      collapseMessageElement.classList.remove('collapse');
      filterContainerElement.classList.remove('collapse');
      filterComponentContainerElement.classList.remove('collapse');
      if (filterTableContainerElement !== undefined) { filterTableContainerElement.classList.remove('collapse'); }
    }
    this.isFilterSectionOpen = !this.isFilterSectionOpen;
  }

  /**
  * filter in headers, studyPath table
  * @param value
  * @param field
  */
  eventFilterStudentsTable(value, field) {
    this.studentsFilterHeaders.map(filterHeader => {
      if (filterHeader['field'] === field) {
        filterHeader['value'] = value;
      }
    });

    this.generateFiltersForStudents();
    this.applyFiltersForStudents();
  }

  /**
   * trigger when sort column header in study path table
   * @param $event
   */
  eventSortStudentsTable(event, col) {
    this.studentsSortingValue = this.studentsSortingValue === 'asc' ? 'desc' : 'asc';
    this.studentsSorting = { column: col.field, value: this.studentsSortingValue };
    this.generateFiltersForStudents();
    this.applyFiltersForStudents();
  }

  /**
   * paginate in students table
   * @param event
   */
  eventPaginateStudentsTable(event) {
    this.studentsPaginationPage = event.page;
    this.studentsRows = event.rows;
    this.generateFiltersForStudents();
    this.applyFiltersForStudents();
  }

  /**
   * open modal to send emails
   */
  eventOpenSendEmailModal(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.position = {
      top: '125px'
    };
    dialogConfig.data = {
      userEmail: this.userEmail,
      UrlApi: 'emails/progress',
      parameters: this.filterParametersSendEmail.concat(this.filterStudentId)
    };
    const dialogRef = this.dialog.open(SendEmailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  // FUNCTIONALITY

  /**
   * define goals-ids according to selected tags
   */
  setGoalIdsByTags() {
    this.selectedGoalIdsByTags = [];
    this.tagsQuery.selectAll().subscribe(response => {
      this.selectedTags.map(selectedTagName => {
        const foundIndex = response.findIndex(tag => tag.name === selectedTagName);
        response[foundIndex].goals.map(goalId => {
          this.selectedGoalIdsByTags.push(Number(goalId));
        });
      });
      this.generateFiltersForStudents();
    });
  }

  /**
   * generate parameters according to the selection
   * in the filters section
   */
  generateFiltersForStudents() {
    let filterExist = false;
    let goalsIds = '';
    let scopesIds = '';
    let adminsUsersIds = '';
    let filtersHeaders = '';
    let sorting = '';

    this.showStudentTable = false;
    this.showReportTable = false;

    this.filterParametersStudents = `?size=${this.studentsRows}&page=${this.studentsPaginationPage}`;

    if (this.selectedGoals.length > 0 || this.selectedGoalIdsByTags.length > 0) {
      filterExist = true;
      goalsIds = `&goals=${this.selectedGoals.concat(this.selectedGoalIdsByTags).join()}`;
    }
    if (this.selectedScopes.length > 0) {
      filterExist = true;
      scopesIds = `&scopes=${this.selectedScopes.join()}`;
    }
    if (this.selectedAdminUsers.length > 0) {
      filterExist = true;
      adminsUsersIds = `&admins=${this.selectedAdminUsers.join()}`;
    }

    if (this.studentsFilterHeaders[0]['value'] || this.studentsFilterHeaders[1]['value']
      || this.studentsFilterHeaders[2]['value']) {
      let existFilterHeader = false;
      this.studentsFilterHeaders.map(filterHeader => {
        if (filterHeader['value']) {
          filterExist = true;
          filtersHeaders += existFilterHeader ? `,${filterHeader['field']}=${filterHeader['value']}`
            : `${filterHeader['field']}=${filterHeader['value']}`;
          existFilterHeader = true;
        }
      });
      if (filtersHeaders) filtersHeaders = `&filters=${filtersHeaders}`;
    }
    if (this.studentsSorting['column'] !== undefined && this.studentsSorting['value'] !== undefined) {
      filterExist = true;
      sorting = `&sort=${this.studentsSorting['column']},${this.studentsSorting['value']}`;
    }
    if (filterExist) {
      this.filterParametersStudents = this.filterParametersStudents.concat(
        `${filtersHeaders}${sorting}${goalsIds}${scopesIds}${adminsUsersIds}`
      );
      this.filterParametersSendEmail = `${filtersHeaders}${sorting}${goalsIds}${scopesIds}${adminsUsersIds}`;
    }
  }

  /**
   * generate parameters according to the selection
   * in the filters section
   */
  generateFiltersForStudentProgressReport() {
    let filterExist = false;
    let goalsIds = '';
    let scopesIds = '';
    let adminsUsersIds = '';

    this.filterParametersReportTable = `?students=${this.studentId}`;

    if (this.selectedGoals.length > 0 || this.selectedGoalIdsByTags.length > 0) {
      filterExist = true;
      goalsIds = `&goals=${this.selectedGoals.concat(this.selectedGoalIdsByTags).join()}`;
    }
    if (this.selectedScopes.length > 0) {
      filterExist = true;
      scopesIds = `&scopes=${this.selectedScopes.join()}`;
    }
    if (this.selectedAdminUsers.length > 0) {
      filterExist = true;
      adminsUsersIds = `&admins=${this.selectedAdminUsers.join()}`;
    }
    if (filterExist) {
      this.filterParametersReportTable = this.filterParametersReportTable.concat(
        `${goalsIds}${scopesIds}${adminsUsersIds}`
      );
    }
  }

  /**
   * apply filters for students table
   */
  applyFiltersForStudents() {
    this.spinnerService.show();
    this.showStudentTable = true;
    this.studentsService.getStudents(this.filterParametersStudents).then(
      (existStudents) => {
        this.spinnerService.hide();
        if (!existStudents) {
          this.messageService.add({
            severity: 'warn',
            summary: this.translate.instant('messages.student-progress.no-students-data-title'),
            detail: this.translate.instant('messages.student-progress.no-students-data')
          });
        }
      },
      (err) => {
        this.spinnerService.hide();
        const errorMessage = this.utils.messageManager(err.error.status, err.error.message);
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('messages.common.error'),
          detail: this.translate.instant(errorMessage)
        });
      }
    );
    this.studentsQuery.selectAll().subscribe(allStudents => {
      this.generateDataStudents(allStudents);
    });
  }

  /**
   * generate report for student progress
   */
  generateReport() {
    this.showReportTable = true;
    const clientOffsetHours = (new Date()).getTimezoneOffset() / 60 * -1;
    const params = this.filterParametersReportTable.concat(`&clientOffsetHours=${clientOffsetHours}`);
    this.studentProgressService.getReportData(params).then(
      (reportData) => {
        this.generateDataReport(reportData);
        if (!this.flagCollapseFilters) {
          this.flagCollapseFilters = true;
          this.eventToggleFilterContainer();
        }
        this.spinnerService.hide();
      },
      (err) => {
        this.spinnerService.hide();
        const errorMessage = this.utils.messageManager(err.error.status, err.error.message);
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('messages.common.error'),
          detail: this.translate.instant(errorMessage)
        });
      }
    );
  }

  // GENERATE DATA AND TABLES

  /**
   * generate data to load students table
   * according to response from api
   */
  generateDataStudents(studentData) {
    this.studentsData = [];
    studentData.map(student => {
      const studentRow = { id: student.id, firstName: student.firstName, lastName: student.lastName,
        email: student.email };
      this.studentsData.push(studentRow);
    });
  }

  /**
   * generate data to load report table
   * according to response from api
   */
  generateDataReport(dataToRender) {
    this.reportData = [];
    Object.entries(dataToRender).map(studentProp => {
      switch (studentProp[0]) {
        case 'lastLogin':
          this.reportData.push({
            label: `${this.translate.instant(`student-progress.report.${this.translate.instant(studentProp[0])}`)}: `,
            value: studentProp[1]
          });
          break;
        case 'firstName':
          this.reportData.push({
            label: `${studentProp[1]} ${dataToRender['lastName']} EdReady Progress Report`,
            classRow: 'student-name-title'
          });
          break;
        case 'lastName':
          break;
        case 'studyPaths':
          let studyPaths;
          studyPaths = studentProp[1];
          studyPaths.map(initialScore => {
            this.reportData.push({ value: '' });
            Object.entries(initialScore).map(initialScoreProp => {
              if (initialScore['initialDiagnosticInProgress']) {
                switch (initialScoreProp[0]) {
                  case 'name':
                    this.reportData.push({
                      label: initialScoreProp[1],
                      classRow: 'study-path-name'
                    });
                    break;
                  case 'initialDiagnosticInProgress':
                    this.reportData.push({
                      label: `${this.translate.instant(`student-progress.report.${this.translate.instant(initialScoreProp[0])}`)}`,
                      classRow: 'tab-value'
                    });
                    break;
                  default:
                }
              } else {
                switch (initialScoreProp[0]) {
                  case 'initialDiagnosticInProgress':
                    break;
                  case 'name':
                    this.reportData.push({
                      label: initialScoreProp[1],
                      classRow: 'study-path-name'
                    });
                    break;

                  case 'timeStudying':
                    const value = initialScoreProp[1] !== '' ? initialScoreProp[1] : '00:00:00';
                    this.reportData.push({
                      label: `${this.translate.instant(`student-progress.report.${this.translate.instant(initialScoreProp[0])}`)}: `,
                      value: value,
                      classRow: 'tab-value'
                    });
                    break;

                  case 'lastTestingActivity':
                    this.reportData.push({
                      label: `${this.translate.instant(`student-progress.report.${this.translate.instant(initialScoreProp[0])}`)}: `,
                      value: initialScoreProp[1] ? initialScoreProp[1] : '',
                      classRow: 'tab-value'
                    });
                    break;

                  case 'dateTargetScoreReached':
                    this.reportData.push({
                      label: `${this.translate.instant(`student-progress.report.${this.translate.instant(initialScoreProp[0])}`)}: `,
                      value: initialScoreProp[1] ? initialScoreProp[1] : '',
                      classRow: 'tab-value'
                    });
                    break;

                  default:
                    this.reportData.push({
                      label: `${this.translate.instant(`student-progress.report.${this.translate.instant(initialScoreProp[0])}`)}: `,
                      value: initialScoreProp[1],
                      classRow: 'tab-value'
                    });
                }
              }
            });
          });
          break;
        default:
          this.reportData.push({
            label: `${this.translate.instant(`student-progress.report.${this.translate.instant(studentProp[0])}`)}: `,
            value: `${studentProp[1]}`
          });
      }
    });
  }

  /**
   * create a tableColumn with all the required properties
   * @param columnsList - columns list
   * @param consolidatedSSColumn - report column
   */
  createTableColumn(columnsList, consolidatedSSColumn: TableColumn) {
    const column = {
      field: consolidatedSSColumn.field,
      header: consolidatedSSColumn.header,
      filter: consolidatedSSColumn.filter,
      sortable: consolidatedSSColumn.sortable,
      colspan: consolidatedSSColumn.colspan,
      rowspan: consolidatedSSColumn.rowspan,
      className: consolidatedSSColumn.className,
      filterMatchMode: 'contains'
    };
    if (!columnsList.some(e => e['field'] === column['field'])) {
      columnsList.push(column);
      if (consolidatedSSColumn.addToReportRow) { this.reportRow[consolidatedSSColumn.field] = ''; }
    }
  }

  /**
   * add fixed columns in the students table
   */
  generateStudentTableFixedColumns() {
    this.createTableColumn(
      this.tableStudentsColumns,
      new TableColumn('id', 'student-progress.students.id', '', false, 'id hidden')
    );

    this.createTableColumn(
      this.tableStudentsColumns,
      new TableColumn('firstName', 'student-progress.students.firstName', '', false, 'firstName')
    );

    this.createTableColumn(
      this.tableStudentsColumns,
      new TableColumn('lastName', 'student-progress.students.lastName', '', false, 'lastName')
    );

    this.createTableColumn(
      this.tableStudentsColumns,
      new TableColumn('email', 'student-progress.students.email', '', false, 'email')
    );
  }

  /**
   * add fixed columns in the report table
   */
  generateReportTableFixedColumns() {
    this.createTableColumn(
      this.tableReportColumns,
      new TableColumn('value', this.translate.instant('student-progress.students.value'), '', false, 'value')
    );
  }
}
