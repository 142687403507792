import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { AdminUsersStore, AdminUsersState } from './admin-users.store';
import { AdminUser } from './admin-users.model';

@Injectable({
  providedIn: 'root'
})
export class AdminUsersQuery extends QueryEntity<AdminUsersState, AdminUser> {
  selectUserName$ = this.select(state => state.ui.userName);

  constructor(protected tagsStore: AdminUsersStore) {
    super(tagsStore);
  }
}
