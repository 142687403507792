import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

/**
 * state
 */
import { TagsStore, TagsState } from './tags.store';
import { Tag } from './tags.model';

@Injectable({
  providedIn: 'root'
})
export class TagsQuery extends QueryEntity<TagsState, Tag> {
  selectUserName$ = this.select(state => state.ui.userName);

  constructor(protected tagsStore: TagsStore) {
    super(tagsStore);
  }
}
