import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

/**
 * state
 */
import { TagsStore } from './tags.store';
import { TagsQuery } from './tags.query';

/**
 * environment variables
 */
import { environment } from '../../../environments/environment';


/**
 * utils services
 */
import { UtilsService } from './../../services/utils.service';


@Injectable({
  providedIn: 'root'
})
export class TagsService {

  /**
   * global variables
   */
  ApiUrl = environment.api;

  /**
   * create header for api request
   */
  httpOptions = this.utilsService.createHttpOptions();

  constructor(
    private http: HttpClient,
    private tagsStore: TagsStore,
    private tagsQuery: TagsQuery,
    private utilsService: UtilsService
  ) { }

  /**
   * set tags in store according to parameter
   */
  setTags(tags) {
    this.tagsStore.set(tags);
  }
}
