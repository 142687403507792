import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

/**
 * model
 */
import { Tag } from './tags.model';

export interface TagsState extends EntityState<Tag> {}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'tags' })
export class TagsStore extends EntityStore<TagsState, Tag> {
  constructor() {
    super();
  }
}
