import { ID } from '@datorama/akita';

import { Deserializable } from '../../shared/models/deserializable.model';

export class Tag implements Deserializable {
  id: ID;
  name: string;
  goals: string[] = new Array;

  constructor(id, name, goals) {
    this.id = id;
    this.name = name;
    this.goals = goals;
  }
  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
