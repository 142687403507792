import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SuiModule } from 'ng2-semantic-ui';
import { PaginatorModule } from 'primeng/paginator';
import { RouterModule } from '@angular/router';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FlatpickrModule } from "angularx-flatpickr";

import { MaterialModule } from '../material.module';

// Custom components

import { FiltersComponent } from './filters/filters.component';
import { DateFiltersComponent } from "./date-filters/date-filters.component";
import { PathComponent } from './path/path.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    PaginatorModule,
    SuiModule,
    TranslateModule.forChild({}),
    CommonModule,
    RouterModule,
    NgMultiSelectDropDownModule.forRoot(),
    FlatpickrModule.forRoot(),
  ],
  declarations: [
    FiltersComponent,
    DateFiltersComponent,
    PathComponent
  ],
  exports: [
    FiltersComponent,
    DateFiltersComponent,
    PathComponent
  ]
})
export class SharedModules { }
