
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { noop } from '@datorama/akita';

/**
 * state
 */
import { Student } from './students.model';
import { StudentsStore } from './students.store';
import { StudentsQuery } from './students.query';

/**
 * environment variables
 */
import { environment } from '../../../environments/environment';

/**
 * services
 */
import { UtilsService } from '../../services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class StudentsService {

  /**
   * global variables
   */
  ApiUrl = environment.api;

  /**
   * set request headers - token
   */
  httpOptions = this.utilsService.createHttpOptions();

  constructor(
    private http: HttpClient,
    private studentsStore: StudentsStore,
    private studentsQuery: StudentsQuery,
    private utilsService: UtilsService
  ) { }

  /**
   * get students from api
   * set students store
   */
  getStudents(params: string = ''): Promise<boolean> {
    return new Promise( (resolve, reject) => {
      const students = this.http.get<Student[]>(`${this.ApiUrl}students${params}`,
      { headers: new HttpHeaders(this.utilsService.createHeaders()), observe: 'response' });
      students.subscribe(resp => {
        const studentsData: Student[] = resp.body;
        const studentsTotal = resp.headers.get('X-Total-Count');
        const existStudents = studentsTotal !== '0';
        this.studentsStore.updateStateGeneral(studentsTotal);
        this.studentsStore.set(studentsData);
        resolve(existStudents);
      },
      err => {
        reject(err);
      });
    });
  }

  /**
   * mock students
   */
  setStudentsNOAPI(): Observable<Student[]> {
    this.studentsStore.add(new Student(206740333, 'Roiner Camacho Esquivel'));
    this.studentsStore.add(new Student(209340877, 'Alejandro Bonilla Salas'));

    let allStudents;
    this.studentsQuery.selectAll().subscribe( response => {
      console.log('response ST:', response);
      allStudents = response;
      const tests = this.studentsQuery.getCount();
      console.log('response ST2:', tests);
    });
    return this.studentsQuery.isPristine ? allStudents : noop();
  }
}
