import { ID } from '@datorama/akita';

export class Path {
  id: ID;
  url: string;
  name: string;
  icon: string;

  constructor(id: ID, url: string, name: string, icon: string = '') {
    this.id = id;
    this.url = url;
    this.name = name;
    this.icon = icon;
  }
}
