import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { noop } from '@datorama/akita';


/**
 * state
 */
import { UsersStore } from './users.store';
import { UsersQuery } from './users.query';


/**
 * environment variables
 */
import { environment } from '../../../environments/environment';


/**
 * utils services
 */
import { UtilsService } from './../../services/utils.service';


@Injectable({
  providedIn: 'root'
})
export class UsersService {
  /**
   * global variables
   */
  ApiUrl = environment.api;

  /**
   * create header for api request
   */
  httpOptions = {} ;

  constructor(
    private http: HttpClient,
    private usersStore: UsersStore,
    private usersQuery: UsersQuery,
    private utilsService: UtilsService
  ) { }

  /**
   * Set user info in store
   * @param parameterToken - token parameter to set in localstorage
   */
  setUserInfo(): Observable<any> {
    this.httpOptions = this.utilsService.createHttpOptions();
    const userInfo = this.http.get( `${this.ApiUrl}users/info`, this.httpOptions);
    userInfo.subscribe( info => {
      const userName = `${info['firstName']} ${info['lastName']}`;
      const college = `${info['customer']['name']}`;
      const customerId = `${info['customer']['id']}`;
      const url = `${info['customer']['url']}`;
      const email = `${info['email']}`;
      this.usersStore.set(info);
      this.updateStateUI(userName, college, customerId, email, url);
    },
    err => {
      console.log('error', err);
    });
    return this.usersQuery.isPristine ? userInfo : noop();
  }

  /**
   * update ui state in store
   * @param userName
   * @param college
   */
  updateStateUI(userName= '', college= '', customerId= '', email= '', url= '') {
    this.usersStore.updateStateUI(userName, college, customerId, email, url);
  }

}
