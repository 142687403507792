import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { noop } from '@datorama/akita';

/**
 * state
 */
import { GoalsStore } from './goals.store';
import { GoalsQuery } from './goals.query';

/**
 * environment variables
 */
import { environment } from '../../../environments/environment';

/**
 * services
 */
import { UtilsService } from '../../services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class GoalsService {

  /**
   * global variables
   */
  ApiUrl = environment.api;

  /**
   * set request headers - token
   */
  httpOptions = this.utilsService.createHttpOptions();

  constructor(
    private http: HttpClient,
    private goalsStore: GoalsStore,
    private goalsQuery: GoalsQuery,
    private utilsService: UtilsService
  ) { }

  /**
   * set goals in the respective store
   */
  setGoals(): Promise<any> {
    return new Promise((resolve, reject) => {
      const goals = this.http.get( `${this.ApiUrl}goals`, this.httpOptions);
      goals.subscribe( info => {
        this.goalsStore.set(info);
        resolve(info);
      },
      err => {
        reject(err);
      });
    });

    // return this.goalsQuery.isPristine ? goals : noop();
  }
}
