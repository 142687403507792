import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { noop } from '@datorama/akita';


/**
 * model
 */
import { AdminUser } from './admin-users.model';
import { AdminUsersStore } from './admin-users.store';
import { AdminUsersQuery } from './admin-users.query';


/**
 * environment variables
 */
import { environment } from '../../../environments/environment';

/**
 * utils services
 */
import { UtilsService } from './../../services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class AdminUsersService {

  /**
   * global variables
   */
  ApiUrl = environment.api;

  /**
   * create header for api request
   */
  httpOptions = this.utilsService.createHttpOptions();

  constructor(
    private http: HttpClient,
    private adminUsersStore: AdminUsersStore,
    private adminUsersQuery: AdminUsersQuery,
    private utilsService: UtilsService
) { }

  setAdminUsers(): Promise<any> {
    return new Promise((resolve, reject) => {
      const adminUsers = this.http.get( `${this.ApiUrl}users/admins`, this.httpOptions);
      adminUsers.subscribe( info => {
        this.adminUsersStore.set(info);
        resolve(info);
      },
      err => {
        reject(err);
      });
    });

    // return this.adminUsersQuery.isPristine ? adminUsers : noop();
  }
}
