import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

/**
 * state
 */
import { StudyPathsStore, StudyPathsState } from './study-paths.store';
import { StudyPath } from './study-paths.model';

@Injectable({
  providedIn: 'root'
})
export class StudyPathsQuery extends QueryEntity<StudyPathsState, StudyPath> {
  /**
   * return the total value in the store - general
   * @type {Observable<string>}
   */
  selectTotal$ = this.select<string>( state => state.general.total );

  constructor(protected studyPathsStore: StudyPathsStore) {
    super(studyPathsStore);
  }
}
