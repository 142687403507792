import { ID } from '@datorama/akita';

import { Deserializable } from '../../shared/models/deserializable.model';

export class Scope implements Deserializable {
  id: ID;
  name: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
