import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import {debounceTime, tap} from 'rxjs/operators';

/**
 * third party components
 */
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MessageService } from 'primeng/api';
/**
 * shared
 */
import { Utils } from '../shared/utils';

/**
 * models
 */
import { TableColumn } from '../shared/models/table/Table.model';
/**
 * state
 */
import { TagsQuery } from '../state/tags';
import { UsersQuery } from '../state/users';
import {SessionsQuery, SessionsService} from "../state/sessions";

import { StudyPathData } from "./study-path-data";
import { SendEmailComponent } from "../shared/modals/send-email/send-email.component";
import {ShareSessionDetailsData} from "./share-session-details-data";
import {ShareStudentsFiltersData} from "./share-students-filters-data";

@Component({
  selector: 'app-session-summary',
  templateUrl: './session-summary.component.html',
  styleUrls: ['./session-summary.component.scss']
})

export class SessionSummaryComponent implements OnInit {

  /**
   * paths for breadcrum component
   */
  pathsToShow = [0, 3];

  /**
   * definitions for global variables
   */
    // filters container
  isFilterSectionOpen = true;
  collapseButtonLabel = 'filters.hide-filters';
  collapseButtonIcon = 'keyboard_arrow_up';

  // session table
  tableSessionsColumns: TableColumn[] = [];
  sessionsData: object[] = [];
  selectedStudents: Object[];
  showSessionTable = false;
  showStudentTable = false;
  filterParametersSessions = '';
  // session filters in header
  sessionsFilterHeaders = [];
  keyUp$ = new Subject<any>();

  // sessions pagination
  sessionsPaginationPage = 0;
  sessionsPaginationLinkSize = 4;
  sessionsPaginationTotalRecords$: Observable<string>;
  // sessions sorting
  sessionsSorting = {};
  sessionSummarySortingValue = '';

  reportRow = {};
  // filters in header
  sessionReportFilterHeaders = [];
  // pagination
  sessionRows = 10;

  // selected values in the filter component
  selectedGoals: number[] = [];

  // send email
  filterParametersSendEmail = '';
  // user information
  userEmail = '';

  /**
   * dom elements
   */
  @ViewChild('filtersContainer') filtersContainer: any;
  @ViewChild('collapseMessage') collapseMessageContainer: any;
  @ViewChild('filterComponentContainer') filterComponentContainer: any;
  @ViewChild('filterTableContainer') filterTableContainer: any;
  @ViewChild('buttonsContainer') buttonsContainer: any;
  @ViewChildren('inputFilter') inputFilters: QueryList<ElementRef>;

  @Output() outShowStudents = new EventEmitter<void>();

  constructor(
    private sessionsService: SessionsService,
    private sessionsQuery: SessionsQuery,
    private spinnerService: Ng4LoadingSpinnerService,
    private messageService: MessageService,
    private translate: TranslateService,
    private usersQuery: UsersQuery,
    private tagsQuery: TagsQuery,
    private dialog: MatDialog,
    private utils: Utils,
    private studyPathData: StudyPathData,
    private shareSessionDetails: ShareSessionDetailsData,
    private sharedFilters: ShareStudentsFiltersData,
    private renderer: Renderer2
  ) {
    this.utils.validateRoute();
  }

  ngOnInit() {
    this.keyUp$.pipe(tap(() => this.utils.handleActiveFilterStyle(this.inputFilters, this.renderer)), debounceTime(1000)).subscribe(data => {
      this.eventFilterSessionsTable(data['value'], data['field']);
    });

    this.sessionsPaginationTotalRecords$ = this.sessionsQuery.selectTotal$;
    this.generateSessionTableFixedColumns();
    const columnsArray = ['firstName', 'lastName', 'email', 'sessionCount', 'totalSessionTime', 'totalStudyTime',
      'retakenUnits', 'retakenTopics', 'resourcesStudiedNumber', 'masteredTopicNumber', 'masteredUnitNumber',
      'gainedScore', 'finalScore']
    this.sessionsFilterHeaders = columnsArray.map(name => ({field: name, value: ''}) )

    this.usersQuery.selectEmail$.subscribe(email => {
      this.userEmail = email;
    });
  }

  getSessionTable() {
    this.sessionSummarySortingValue = this.sessionSummarySortingValue === 'asc' ? 'desc' : 'asc';
    this.generateFiltersForSessionSummary();
    this.applyFiltersForSessionSummary();
  }

  /**
   * trigger when sort column header in study path table
   * @param $event
   */
  eventSortSessionsTable(event, col) {
    this.sessionSummarySortingValue = this.sessionSummarySortingValue === 'asc' ? 'desc' : 'asc';
    this.sessionsSorting = { column: col.field, value: this.sessionSummarySortingValue };
    this.generateFiltersForSessionSummary();
    this.applyFiltersForSessionSummary();
  }

  /**
   * filter in headers, studyPath table
   * @param value
   * @param field
   */
  eventFilterSessionTable(value, field) {
    const filteredRow = this.sessionReportFilterHeaders.filter(objRow => {
      return objRow['field'] === field;
    });

    if (filteredRow.length === 0) {
      this.sessionReportFilterHeaders.push({ field, value });
    } else {
      this.sessionReportFilterHeaders.map(filterHeader => {
        if (filterHeader['field'] === field) {
          filterHeader['value'] = value;
        }
      });
    }
  }

  /**
   * triggered with the show/hide button in the filters component
   */
  eventToggleFilterContainer() {
    const filterContainerElement = this.filtersContainer.nativeElement;
    const collapseMessageElement = this.collapseMessageContainer.nativeElement;
    const filterComponentContainerElement = this.filterComponentContainer.nativeElement;
    const filterTableContainerElement = this.filterTableContainer !== undefined ? this.filterTableContainer.nativeElement : undefined;
    const buttonsContainerElement = this.buttonsContainer !== undefined ? this.buttonsContainer.nativeElement : undefined;

    if (this.isFilterSectionOpen) {
      this.collapseButtonIcon = 'keyboard_arrow_down';
      this.collapseButtonLabel = 'filters.show-filters';
      collapseMessageElement.classList.add('collapse');
      filterContainerElement.classList.add('collapse');
      filterComponentContainerElement.classList.add('collapse');
      if (filterTableContainerElement !== undefined) { filterTableContainerElement.classList.add('collapse'); }
      if (buttonsContainerElement) { buttonsContainerElement.classList.add('colastNamellapse'); }
    } else {
      this.collapseButtonIcon = 'keyboard_arrow_up';
      this.collapseButtonLabel = 'filters.hide-filters';
      collapseMessageElement.classList.remove('collapse');
      filterContainerElement.classList.remove('collapse');
      filterComponentContainerElement.classList.remove('collapse');
      if (filterTableContainerElement !== undefined) { filterTableContainerElement.classList.remove('collapse'); }
      if (buttonsContainerElement) { buttonsContainerElement.classList.remove('collapse'); }
    }
    this.isFilterSectionOpen = !this.isFilterSectionOpen;
  }

  /**
   * create a tableColumn with all the required properties
   * @param columnsList - columns list
   * @param sessionSSColumn - report column
   */
  createTableColumn(columnsList, sessionSSColumn: TableColumn) {
    const column = {
      field: sessionSSColumn.field,
      header: sessionSSColumn.header,
      filter: sessionSSColumn.filter,
      tooltip: sessionSSColumn.tooltip,
      sortable: sessionSSColumn.sortable,
      colspan: sessionSSColumn.colspan,
      rowspan: sessionSSColumn.rowspan,
      className: sessionSSColumn.className,
      filterMatchMode: 'contains'
    };
    if (!columnsList.some(e => e['field'] === column['field'])) {
      columnsList.push(column);
      if (sessionSSColumn.addToReportRow) { this.reportRow[sessionSSColumn.field] = ''; }
    }
  }

  /**
   * add fixed columns in the students table
   */
  generateSessionTableFixedColumns() {
    const tableColumnArray = [{field: 'studentId', header: 'student-activity.students.id', className: 'id hidden'},
      {field: 'firstName', header: 'student-activity.students.firstName', className: 'firstName', filter: true},
      {field: 'lastName', header: 'student-activity.students.lastName', className: 'lastName', filter: true},
      {field: 'email', header: 'student-activity.students.email', className: 'email', filter: true},
      {field: 'sessionCount', header: 'student-activity.sessions.sessionCount', className: 'sessionCount'},
      {field: 'totalSessionTime', header: 'student-activity.sessions.totalSessionTime', className: 'totalSessionTime'},
      {field: 'totalStudyTime', header: 'student-activity.sessions.totalStudyTime', className: 'totalStudyTime'},
      {field: 'retakenUnits', header: 'student-activity.sessions.retakenUnits', className: 'retakenUnits'},
      {field: 'retakenTopics', header: 'student-activity.sessions.retakenTopics', className: 'retakenTopics'},
      {field: 'resourcesStudiedNumber', header: 'student-activity.sessions.resourcesStudiedNumber', className: 'resourcesStudiedNumber'},
      {field: 'masteredTopicNumber', header: 'student-activity.sessions.masteredTopicNumber', className: 'masteredTopicNumber'},
      {field: 'masteredUnitNumber', header: 'student-activity.sessions.masteredUnitNumber', className: 'masteredUnitNumber'},
      {field: 'gainedScore', header: 'student-activity.sessions.gainedScore', className: 'gainedScore'},
      {field: 'finalScore', header: 'student-activity.sessions.finalScore', className: 'finalScore'},
    ]

    tableColumnArray.forEach(column => {
      this.createTableColumn(
        this.tableSessionsColumns,
        new TableColumn(column.field, column.header, '', false, column.className, column.filter)
      );
    })
  }

  getPaginationParams() {
    return `?size=${this.sessionRows}&page=${this.sessionsPaginationPage}`;
  }

  /**
   * generate parameters according to the selection
   * in the filters section
   */
  generateFiltersForSessionSummary() {
    let filterExist = false;
    let group = '';
    let dates = '';
    let assessment = '';
    let filtersHeaders = '';
    let sorting = '';

    this.showStudentTable = false;
    this.filterParametersSessions = '';

    if (this.studyPathData.getSelectedGoals().length > 0) {
      filterExist = true;
      group = `&groups=${this.studyPathData.getSelectedGoalsIds()}`;
    }
    if (this.studyPathData.getSelectedScope() != null) {
      filterExist = true;
      assessment = `&assessment=${this.studyPathData.getSelectedScope().id}`;
    }

    if (this.sessionsFilterHeaders[0]['value'] || this.sessionsFilterHeaders[1]['value']
      || this.sessionsFilterHeaders[2]['value']) {
      let existFilterHeader = false;
      this.sessionsFilterHeaders.map(filterHeader => {
        if (filterHeader['value']) {
          filterExist = true;
          filtersHeaders += existFilterHeader ? `,${filterHeader['field']}=${filterHeader['value']}`
            : `${filterHeader['field']}=${filterHeader['value']}`;
          existFilterHeader = true;
        }
      });
      if (filtersHeaders) filtersHeaders = `&filters=${filtersHeaders}`;
    }
    if (this.sessionsSorting['column'] !== undefined && this.sessionsSorting['value'] !== undefined) {
      filterExist = true;
      sorting = `&sort=${this.sessionsSorting['column']},${this.sessionsSorting['value']}`;
    }

    dates = this.generateDateFiltersForSessionSummary();
    if (dates) {
      filterExist = true;
    }

    if (filterExist) {
      this.filterParametersSessions = this.filterParametersSessions.concat(
        `${filtersHeaders}${sorting}${group}${assessment}${dates}`
      );
    }
  }

  generateDateFiltersForSessionSummary() {
    if (!this.sharedFilters.getSelectedDates()) {
      return '';
    }

    return this.sharedFilters.getSelectedDates().toQueryString();
  }

  /**
   * apply filters for sessions table
   */
  applyFiltersForSessionSummary() {
    this.spinnerService.show();
    this.showSessionTable = true;
    this.showStudentTable = false;
    const params = this.getPaginationParams() + this.filterParametersSessions;
    this.sessionsService.getStudentSessionSummary(params).then(
      (sessionResponse) => {
        this.spinnerService.hide();
        if (!sessionResponse) {
          this.messageService.add({
            severity: 'warn',
            summary: this.translate.instant('messages.students-list.no-sessions-data-title'),
            detail: this.translate.instant('messages.students-list.no-sessions-data')
          });
        } else {
          this.generateDataSessions(sessionResponse.sessionData);
        }
      },
      (err) => {
        this.spinnerService.hide();
        const errorMessage = this.utils.messageManager(err.error.status, err.error.message);
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('messages.common.error'),
          detail: this.translate.instant(errorMessage)
        });
      }
    );
/*    this.sessionsQuery.selectAll().subscribe(allSessions => {
      this.generateDataSessions(allSessions);
    });*/
  }

  /**
   * generate data to load sessions table
   * according to response from api
   */
  generateDataSessions(sessionData) {
    this.sessionsData = [];
    this.sessionsData = sessionData.map(session => {
      return { id: session.id, firstName: session.firstName, lastName: session.lastName, email: session.email,
      studentId: session.studentId, sessionCount: session.sessionCount, totalSessionTime: session.totalSessionTime,
      totalStudyTime: session.totalStudyTime, retakenUnits: session.retakenUnits, retakenTopics: session.retakenTopics,
      resourcesStudiedNumber: session.resourcesStudiedNumber,
      masteredTopicNumber: session.masteredTopicNumber, masteredUnitNumber: session.masteredUnitNumber,
      gainedScore: session.gainedScore, finalScore: session.finalScore};
      //this.sessionsData.push(sessionRow);
    });
  }

  /**
   * filter in headers, studyPath table
   * @param value
   * @param field
   */
  eventFilterSessionsTable(value, field) {
    this.sessionsFilterHeaders.map(filterHeader => {
      if (filterHeader['field'] === field) {
        filterHeader['value'] = value;
      }
    });
    this.generateFiltersForSessionSummary();
    this.applyFiltersForSessionSummary();

  }

  /**
   * paginate in sessions table
   * @param event
   */
  eventPaginateSessionsTable(event) {
    this.sessionsPaginationPage = event.page;
    this.sessionRows = event.rows;
    this.generateFiltersForSessionSummary();
    this.applyFiltersForSessionSummary();
  }

  eventHideSessionTable() {
    this.showSessionTable = false;
  }

  eventViewStudentSessionDetailTable(row) {
    this.showSessionTable = false;
    this.shareSessionDetails.changeStudentIdSelected(row['studentId']);
    this.shareSessionDetails.changeStudentName(row['firstName'] + " " + row['lastName']);
    this.shareSessionDetails.changeStudentEmail(row['email']);
  }

  /**
   * open modal to send emails
   */
  eventOpenSendEmailModal(): void {
    if (this.selectedStudents === undefined || this.selectedStudents.length <= 0) {
      this.messageService.add({
        severity: 'warn',
        summary: this.translate.instant('messages.common.warning'),
        detail: this.translate.instant('messages.students-list.no-students-selection')
      });
      return;
    }

    const studentList = this.selectedStudents.map(student => {
      return student['studentId'];
    }).join();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.position = {
      top: '125px'
    };

    dialogConfig.data = {
      userEmail: this.userEmail,
      UrlApi: 'reports/students/activity/session-summary/csv',
      parameters: this.filterParametersSessions,
      selectionParameters: `&students=${studentList}`,
      showDataOptions: true,
    };
    const dialogRef = this.dialog.open(SendEmailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  showStudents(): void {
    this.outShowStudents.emit();
  }


}
