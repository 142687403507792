import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/authorization',
    pathMatch: 'full'
  },
  {
    path: 'authorization',
    loadChildren: '../authorization/authorization.module#AuthorizationModule'
  },
  {
    path: 'home',
    loadChildren: '../home-page/home-page.module#HomePageModule'
  },
  {
    path: 'consolidated_scores',
    loadChildren: '../consolidated-students-scores/consolidated-students-scores.module#ConsolidatedStudentsScoresModule'
  },
  {
    path: 'student_progress',
    loadChildren: '../student-progress/student-progress.module#StudentProgressModule'
  },
  {
    path: 'students_list',
    loadChildren: '../student-activity/students-list.module#StudentsListModule'
  },
  {
    path: 'advanced_reporting_filters',
    loadChildren: '../student-activity/advanced-reporting-filters/advanced-reporting-filters.module#AdvancedReportingFiltersModule'
  },
  {
    path: 'session_detail_options',
    loadChildren: '../student-activity/session-options.module#SessionOptionsModule'
  },
  {
    path: 'logout',
    loadChildren: '../logout/logout.module#LogoutModule'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
