import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { noop } from '@datorama/akita';


/**
 * state
 */
import { Scope } from './scopes.model';
import { ScopesStore } from './scopes.store';
import { ScopesQuery } from './scopes.query';


/**
 * environment variables
 */
import { environment } from '../../../environments/environment';


/**
 * utils services
 */
import { UtilsService } from './../../services/utils.service';
import {Goal} from "../goals";


@Injectable({
  providedIn: 'root'
})
export class ScopesService {

  /**
   * global variables
  */
  ApiUrl = environment.api;

  /**
   * create header for api request
   */
  httpOptions = this.utilsService.createHttpOptions();

  constructor(
    private http: HttpClient,
    private scopesStore: ScopesStore,
    private scopesQuery: ScopesQuery,
    private utilsService: UtilsService
  ) { }

  setScopes(goals:Goal[]): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.ApiUrl}scopes` + (goals ? `?goals=${goals.map(x => x.id).join()}` : '');
      const scopes = this.http.get( url, this.httpOptions);
      scopes.subscribe( info => {
        this.scopesStore.set(info);
        resolve(info);
      },
      err => {
        reject(err);
      });
    });
  }
}
