import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Custom Components
import { StudentsListComponent } from './students-list.component';

const studentActivityRoutes: Routes = [
  { path: '', component: StudentsListComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(studentActivityRoutes),
  ],
  exports: [
    RouterModule
  ]
})

export class StudentsListRoutingModule { }
