import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

/**
 * state
 */
import { StudyPath } from './study-paths.model';
import { StudyPathsStore } from './study-paths.store';

/**
 * environment variables
 */
import { environment } from '../../../environments/environment';

/**
 * utils services
 */
import { UtilsService } from '../../services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class StudyPathsService {
  /**
   * global variables
   */
  ApiUrl = environment.api;

  /**
   * create header for api request
   */
  httpOptions = this.utilsService.createHttpOptions();

  constructor(
    private studyPathsStore: StudyPathsStore,
    private utilsService: UtilsService,
    private http: HttpClient,
  ) { }

  /**
   * get study-pats from api request
   * update store
   */
  getStudyPaths(params: string = ''): Promise<boolean> {
    return new Promise( (resolve, reject) => {
      const studyPaths = this.http.get<StudyPath[]>(`${this.ApiUrl}studyPaths${params}`,
        { headers: new HttpHeaders(this.utilsService.createHeaders()), observe: 'response' });
      studyPaths.subscribe(resp => {
        const studyPathsData: StudyPath[] = resp.body;
        const studyPathsTotal = resp.headers.get('X-Total-Count');
        this.studyPathsStore.updateStateGeneral(studyPathsTotal);
        this.studyPathsStore.set(studyPathsData);
        const existStudyPaths = studyPathsData.length !== 0;
        resolve(existStudyPaths);
      },
      err => {
        reject(err);
      });
    });
  }

  /**
   * get study-paths with students enrolled from api request
   * update store
   */
  getStudyPathsWithStudentsEnrolled(params: string = ''): Promise<boolean> {
    return new Promise( (resolve, reject) => {
      const studyPaths = this.http.get<StudyPath[]>(`${this.ApiUrl}studyPathsWithStudentsEnrolled${params}`,
        { headers: new HttpHeaders(this.utilsService.createHeaders()), observe: 'response' });
      studyPaths.subscribe(resp => {
          const studyPathsData: StudyPath[] = resp.body;
          const studyPathsTotal = resp.headers.get('X-Total-Count');
          this.studyPathsStore.updateStateGeneral(studyPathsTotal);
          this.studyPathsStore.set(studyPathsData);
          const existStudyPaths = studyPathsData.length !== 0;
          resolve(existStudyPaths);
        },
        err => {
          reject(err);
        });
    });
  }
}
