import { Injectable } from '@angular/core';
import {Goal} from "../state/goals";
import {Scope} from "../state/scopes";

@Injectable({
  providedIn: 'root',
})
export class StudyPathData {

  private selectedGoals: Goal[];
  private selectedScope: Scope;

  constructor() {
  }

  setSelectedGoals(selectedGoals: Goal[]) {
    this.selectedGoals = selectedGoals;
  }

  setSelectedScope(selectedScope: any) {
    this.selectedScope = selectedScope;
  }

  getSelectedGoals() {
    return this.selectedGoals;
  }

  getSelectedGoalsIds() {
    return this.selectedGoals.map(goal => goal.id).join(',');
  }

  getSelectedGoalsNames() {
    return this.selectedGoals.map(goal => goal.name).join(',');
  }

  getSelectedScope() {
    return this.selectedScope;
  }
}
