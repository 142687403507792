import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { Ng4LoadingSpinnerModule  } from 'ng4-loading-spinner';

/**
 * custom modules
 */
import { SharedModules } from '../shared/shared.module';
import { MaterialModule } from '../material.module';
import { MessageService } from 'primeng/api';
import { EmailService } from '../services/email.service';
import { ToastModule } from 'primeng/toast';

/**
 * state
 */
import { StudentProgressRoutingModule } from './student-progress-routing.module';
import { StudentProgressComponent } from './student-progress.component';

@NgModule({
  imports: [
    Ng4LoadingSpinnerModule.forRoot(),
    CommonModule,
    StudentProgressRoutingModule,
    FormsModule,
    MaterialModule,
    TableModule,
    PaginatorModule,
    TranslateModule.forChild({}),
    ToastModule,
    // Custom modules
    SharedModules
  ],
  declarations: [
    StudentProgressComponent,
  ],
  exports: [
    StudentProgressComponent
  ],
  providers: [
    MessageService,
    EmailService
  ]
})
export class StudentProgressModule { }
