import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { GoalsStore, GoalsState } from './goals.store';
import { Goal } from './goals.model';

@Injectable({
  providedIn: 'root'
})
export class GoalsQuery extends QueryEntity<GoalsState, Goal> {
  selectUserName$ = this.select(state => state.ui.userName);

  constructor(protected goalsStore: GoalsStore) {
    super(goalsStore);
  }
}
