import * as moment from 'moment';
import { DateTime } from 'luxon';

export class DateSelection {

  private readonly _local = DateTime.local();

  private _selected: boolean = false;
  private _start: Date = undefined;
  private _end: Date = undefined;
  private _excludedDates: Date[] = []
  private _startTime: Date = undefined;
  private _endTime: Date = undefined;

  constructor() {
    this._selected = false;
    this._start = undefined;
    this._end = undefined;
    this._excludedDates = [];
    this._startTime = undefined;
    this._endTime = undefined;
  }

  get selected(): boolean {
    return this._selected;
  }

  set selected(value: boolean) {
    this._selected = value;
  }

  get start(): Date {
    return this._start;
  }

  set start(value: Date) {
    this._start = value;
  }

  formattedStart() {
    return moment(this.start).utc().format();
  }

  utcStart() {
    return this.formattedStart();
  }

  get end(): Date {
    return this._end;
  }

  set end(value: Date) {
    this._end = value;
  }

  formattedEnd() {
    return moment(this.end).utc().format();
  }

  utcEnd() {
    return moment(this.end).endOf('day').utc().format();
  }

  get excludedDates(): Date[] {
    return this._excludedDates;
  }

  set excludedDates(value: Date[]) {
    this._excludedDates = value;
  }

  formattedExcludedDates() {
    const dates: string[] = [];
    this._excludedDates.forEach(date => {
      dates.push(moment(date).utc().format())
    });
    return dates.join(', ');
  }

  get startTime(): Date {
    return this._startTime;
  }

  set startTime(value: Date) {
    this._startTime = value;
  }

  get endTime(): Date {
    return this._endTime;
  }

  set endTime(value: Date) {
    this._endTime = value;
  }

  get selectedTimes(): boolean {
    if (this._startTime && this._endTime) {
      return true;
    }

    return false;
  }

  get timeRange(): string {
    if (!this.selectedTimes) {
      return '';
    }

    return `${this.formatLocalTime(this._startTime)} to ${this.formatLocalTime(this._endTime)}`;
  }

  setDefaultValues():void {
    const now = new Date();
    this._selected = true;

    this._end = new Date(now.getFullYear(), now.getMonth() , now.getDate());
    this._start = new Date(now.getFullYear(), now.getMonth() , this._end.getDate() - 6);

    this._excludedDates = [];

    this._startTime = new Date();
    this._startTime.setHours(8, 0, 0);
    this._endTime = new Date();
    this._endTime.setHours(17, 0, 0);
  }

  toQueryString() {

    if (!this.selected) {
      return '';
    }

    let dates = `&dates.from=${this.formattedStart()}&dates.to=${this.utcEnd()}&dates.timezone=${this.getTimezone()}`;
    if (this.excludedDates.length) {
      dates += `&dates.excludedDates=${this.formattedExcludedDates()}`
    }

    if (this.selectedTimes) {
      dates += `&dates.fromTime=${this.formatTime(this.startTime)}`;
      dates += `&dates.toTime=${this.formatTime(this.endTime)}`;
    }

    return dates;
  }

  formatDate(date:Date) {
    return moment(date).utc().format();
  }

  formatTime(time:Date) {
    return DateTime.fromJSDate(time).toFormat('HH:mm');
  }

  formatLocalTime(time:Date) {
    return DateTime.fromJSDate(time).toFormat('hh:mm a');
  }

  getTimezone(): string {
    return this._local.zoneName;
  }

}
