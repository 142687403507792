import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
declare let require: any;

/**
 * state
 */
import { UsersQuery } from '../../state/users/index';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  /**
   * definitions for global variables
   */
  username: string;

  constructor(
    private usersQuery: UsersQuery,
  ) { }

  ngOnInit() {
    this.usersQuery.selectUserName$.subscribe( response => this.username = response);

    let theme = environment.theme;
    require(`style-loader!./footer.component.scss`);
    if (theme != 'default') {
      require(`style-loader!./footer.component.${theme}.scss`);
    }
  }

}
