import { Component, Inject, ViewChild, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

/**
 * third party components
 */
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MessageService } from 'primeng/api';

/**
 * services
 */
import { EmailService } from '../../../services/email.service';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss']
})
export class SendEmailComponent {

  listOfEmails: string;

  UrlApi: string;
  parameters: string;
  selectionParameters: string;
  userEmail: string;

  // data options
  showDataOptions: boolean = false;
  dataOptions: string[] = [
    'selected-data',
    'all-data'
  ];
  selectedDataOption = this.dataOptions[0];

  // format options
  formatOptions: string[] = [
    'csv'
  ];
  selectedFormatOption = this.formatOptions[0];

  @ViewChild('listOfEmails') inputListOfEmails: any;

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEventEscape(event: KeyboardEvent) {
    this.close();
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleKeyboardEventEnter(event: KeyboardEvent) {
    this.sendEmail(event);
  }

  constructor(
    public dialogRef: MatDialogRef<SendEmailComponent>,
    private emailService: EmailService,
    private ng4LoadingSpinnerService: Ng4LoadingSpinnerService,
    private messageService: MessageService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) data
    ) {
      this.UrlApi = data.UrlApi;
      this.parameters = data.parameters;
      this.selectionParameters = data.selectionParameters || '';
      this.userEmail = data.userEmail;
      this.showDataOptions = data.showDataOptions || false;
      if (data.formatOptions) {
        this.formatOptions = data.formatOptions;
      }
    }

  /**
   * close modal
   */
  close(): void {
    this.dialogRef.close();
  }

  /**
   * send emails to specific list of emails
   * using a URL API parameter
   * @param $event
   */
  sendEmail($event) {
    this.listOfEmails = this.userEmail;

    if (this.inputListOfEmails.nativeElement.value !== '') {
      this.listOfEmails = this.listOfEmails.concat(`,${this.inputListOfEmails.nativeElement.value}`);
    }
    this.ng4LoadingSpinnerService.show();
    const clientOffsetHours = (new Date()).getTimezoneOffset() / 60 * -1;

    let parameters = `?clientOffsetHours=${clientOffsetHours}&emails=${encodeURIComponent(this.listOfEmails)}&email=${encodeURIComponent(this.listOfEmails)}${this.parameters}`;
    if (this.selectedDataOption === this.dataOptions[0]) {
      parameters = `${parameters}${this.selectionParameters}`
    }

    this.emailService.sendEmail(this.UrlApi, `${parameters}`).then(
      () => {
        this.ng4LoadingSpinnerService.hide();
        this.close();
        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('messages.common.success'),
          detail: this.translate.instant('messages.send-email.success')
        });
      },
      (err) => {
        const errorStatus = err.error.status;
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('messages.common.error'),
          detail: this.translate.instant('messages.send-email.error')
        });
        this.ng4LoadingSpinnerService.hide();
      }
    );
  }
}
