import {Component, OnInit, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';

/**
 * state
 */
import { Scope, ScopesService } from '../../state/scopes';
import { Goal, GoalsService } from '../../state/goals';

/**
 * third party components
 */
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MessageService } from 'primeng/api';

/**
 * shared
 */
import { Utils } from '../../shared/utils';
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

@Component({
  selector: 'student-activity-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class StudentActivityFiltersComponent implements OnInit {

  /**
   * output events for filters component
   * each component use this outputs to control component behavior
   */
  @Output() outSelectGoalsEvent = new EventEmitter<any>();
  @Output() outSelectScopeEvent = new EventEmitter<any>();
  @Output() outApplyFiltersEvent = new EventEmitter<any>();

  /**
   * lists for filter components elements
   */
  goals: Goal[] = [];
  selectedGoals = [];
  scopes: Scope[] = [];
  selectedScope:Scope[] = [];
  readyClass = 'hidden';

  /**
   * styles for generate button
   */
  generateButtonStatus = '';
  generateButtonClass = 'generate';

  /**
   * filters component
   */
  goalsDropdownSettings = {};
  scopesDropdownSettings = {};

  ngSelectedGoals: Goal[] = [];
  ngSelectedScopes: Scope[] = [];

  @ViewChild('scopeFilterHelper') scopeFilterHelper: ElementRef;
  @ViewChild('scopeFilterError') scopeFilterError: ElementRef;

  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private messageService: MessageService,
    private translate: TranslateService,
    private goalsService: GoalsService,
    private scopesService: ScopesService,
    private router: Router,
    private utils: Utils
  ) { }

  ngOnInit() {

    this.loadInitialData();

    this.goalsDropdownSettings = {
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 30,
      allowSearchFilter: true,
      enableCheckAll: false,
    };

    this.scopesDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 30,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
    };
  }

  showError (err) {
    this.spinnerService.hide();

    const errorMessage = this.utils.messageManager(err.error.status, err.error.message);
    this.messageService.add({
      severity: 'error',
      summary: this.translate.instant('messages.common.error'),
      detail: this.translate.instant(errorMessage)
    });
  }

  /**
   * load initial data in each selector element
   */
  loadInitialData() {
    this.spinnerService.show();

    const goalsPromise = this.goalsService.setGoals();
    const scopesPromise = this.scopesService.setScopes([]);

    Promise.all([goalsPromise, scopesPromise])
      .then(
        (values) => {
          const goals = values[0];
          const scopes = values[1];

          this.goals = this.getItemsFromObject(goals);
          this.scopes = scopes.sort(function(a, b) {
            return a.name.localeCompare(b.name);
          });

          this.readyClass = '';
          this.spinnerService.hide();
        },
        this.showError
      );
  }

  loadScopes () {
    this.spinnerService.show();

    this.scopesService.setScopes(this.ngSelectedGoals).then((values) => {
      const scopeIds = values.map(x => x.id);
      if (this.ngSelectedScopes.length > 0 && !scopeIds.includes(this.ngSelectedScopes[0].id)) {
        this.ngSelectedScopes = [];
      }
      this.scopes = values.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });
      this.spinnerService.hide();
    });
  }

  /**
   * set array of objects to show in each selector
   * { id, name } - value to show and value to select
   * @param objectList - api request
   */
  getItemsFromObject(objectList) {
    return objectList.map(objectItem => {
      return {
        id: objectItem.id,
        name: objectItem.name
      };
    });
  }

  /**
   * triggered when a goal is selected/deselected
   * @param event
   */
  eventSelectionGoals(event) {
    this.loadScopes();

    this.outSelectGoalsEvent.emit(this.ngSelectedGoals);
  }

  /**
   * triggered when a scope is selected/deselected
   * @param event
   */
  eventSelectionScopes() {
    const scope = this.ngSelectedScopes.length > 0 ? this.ngSelectedScopes[0] : null;
    this.outSelectScopeEvent.emit(scope);
    this.filtersValidation();
  }

  /**
   * triggered when apply filters button
   * @param event
   */
  eventApplyFilters(event) {
    if (!this.filtersValidation()) {
      this.outApplyFiltersEvent.emit(event);
      this.router.navigate(['/students_list']);
    }
  }

  filtersValidation() {
    let error = false;

    if (this.ngSelectedScopes.length < 1) {
      error = true;
      this.scopeFilterError.nativeElement.classList.remove('hidden');
      this.scopeFilterHelper.nativeElement.classList.add('hidden');
    } else {
      this.scopeFilterError.nativeElement.classList.add('hidden');
      this.scopeFilterHelper.nativeElement.classList.remove('hidden');
    }

    return error;
  }

}
