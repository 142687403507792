import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

/**
 * custom models
 */
import { AdminUser } from './admin-users.model';

export interface AdminUsersState extends EntityState<AdminUser> {}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'adminUsers' })
export class AdminUsersStore extends EntityStore<AdminUsersState, AdminUser> {
  constructor() {
    super();
  }
}
