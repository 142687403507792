import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { ScopesStore, ScopesState } from './scopes.store';
import { Scope } from './scopes.model';

@Injectable({
  providedIn: 'root'
})
export class ScopesQuery extends QueryEntity<ScopesState, Scope> {
  selectUserName$ = this.select(state => state.ui.userName);

  constructor(protected scopesStore: ScopesStore) {
    super(scopesStore);
  }
}
