import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DatePipe} from '@angular/common';

/**
 * third party modules
 */
import {AkitaNgDevtools} from '@datorama/akita-ngdevtools';
import {SuiModule} from 'ng2-semantic-ui';
import {Ng4LoadingSpinnerModule} from 'ng4-loading-spinner';

/**
 * custom modules
 */
import {AppRoutingModule} from './routing/app-routing.module';
import {MaterialModule} from './material.module';
import {SharedModules} from './shared/shared.module';
import {ConsolidatedStudentsScoresModule} from './consolidated-students-scores/consolidated-students-scores.module';
import {StudentProgressModule} from './student-progress/student-progress.module';
import {StudentsListModule} from './student-activity/students-list.module';
import {AdvancedReportingFiltersModule} from './student-activity/advanced-reporting-filters/advanced-reporting-filters.module'

/**
 * custom components
 */
import {AppComponent} from './app.component';
import {HeaderComponent} from './shared/header/header.component';
import {FooterComponent} from './shared/footer/footer.component';
import {SendEmailComponent} from './shared/modals/send-email/send-email.component';
import {SendSessionEmailComponent} from './shared/modals/send-session-email/send-session-email.component';

/**
 * custom services
 */
import {UtilsService} from './services/utils.service';
import {Utils} from './shared/utils';
import {ShareStudentsFiltersData} from "./student-activity/share-students-filters-data";
import {FormsModule} from "@angular/forms";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SuiModule,
    AkitaNgDevtools.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    // Custom modules
    AppRoutingModule,
    MaterialModule,
    SharedModules,
    ConsolidatedStudentsScoresModule,
    StudentProgressModule,
    StudentsListModule,
    AdvancedReportingFiltersModule,
    FormsModule
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SendEmailComponent,
    SendSessionEmailComponent
  ],
  providers: [
    UtilsService,
    Utils,
    DatePipe,
    ShareStudentsFiltersData
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    SendEmailComponent,
    SendSessionEmailComponent
  ]
})
export class AppModule {
}
