import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShareSessionDetailsData {

  private studentIdSelected: string;
  private studentName: string;
  private studentEmail: string;
  private dateRange: string;
  private excludedDates: string;
  private timeRange: string;

  private studentIdSelectedSource = new BehaviorSubject(this.studentIdSelected);
  private studentNameSource = new BehaviorSubject(this.studentName);
  private studentEmailSource = new BehaviorSubject(this.studentEmail);
  private dateRangeSource = new BehaviorSubject(this.dateRange);
  private excludedDatesSource = new BehaviorSubject(this.excludedDates);
  private timeRangeSource = new BehaviorSubject(this.timeRange);

  currentStudentIdSelected = this.studentIdSelectedSource.asObservable();
  currentStudentName = this.studentNameSource.asObservable();
  currentStudentEmail = this.studentEmailSource.asObservable();
  currentDateRange = this.dateRangeSource.asObservable();
  currentExcludedDates = this.excludedDatesSource.asObservable();
  currentTimeRange = this.timeRangeSource.asObservable();

  constructor() {
  }

  changeStudentIdSelected(studentIdSelected: any) {
    this.studentIdSelectedSource.next(studentIdSelected);
  }

  changeStudentName(studentName: string) {
    this.studentNameSource.next(studentName);
  }

  changeStudentEmail(studentEmail: string) {
    this.studentEmailSource.next(studentEmail);
  }

  changeDateRange(dateRange: string) {
    this.dateRangeSource.next(dateRange);
  }

  changeExcludedDates(excludedDates: string) {
    this.excludedDatesSource.next(excludedDates);
  }

  changeTimeRange(timeRange: string) {
    this.timeRangeSource.next(timeRange);
  }
}
