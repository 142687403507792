import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { noop } from '@datorama/akita';

/**
 * state
 */
import { ThemesStore } from './themes.store';
import { Theme } from './themes.model';
import { ThemesQuery } from './themes.query';

/**
 * environment variables
 */
import { environment } from '../../../environments/environment';

/**
 * services
 */
import { UtilsService } from '../../services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class ThemesService {

  /**
   * global variables
   */
  EdReadyApiUrl = environment.edReadyApi;
  ApiUrl = environment.api;

  /**
   * set request headers - token
   */
  httpOptions = {};

  constructor(
    private http: HttpClient,
    private themesStore: ThemesStore,
    private themeQuery: ThemesQuery,
    private utilsService: UtilsService
  ) {
  }

  /**
   * set theme in the respective store
   */
  setCustomerLogo(bertToken = ''): Observable<any> {

    this.httpOptions = this.utilsService.createHttpOptions(bertToken);
      const logo = this.http.get(`${this.ApiUrl}images`, this.httpOptions);
      logo.subscribe(logoUrl => {
        const theme = new Theme(1, 'theme', logoUrl);
        const themeList = [];
        themeList.push(theme);
        this.themesStore.set(themeList);
        this.updateStateUI(logoUrl['url']);
      });
      return this.themeQuery.isPristine ? logo : noop();
  }

  /**
   * update ui state in store
   * @param logoUrl
   */
  updateStateUI(logoUrl= '') {
    this.themesStore.updateStateUI(logoUrl);
  }
}
