import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {DateSelection} from "../shared/date-filters/models/date-selection";
import {Scope} from "../state/scopes";
import {Goal} from "../state/goals";

@Injectable({
  providedIn: 'root',
})
export class ShareStudentsFiltersData {

  private filtered: boolean = false;
  private _selectedGoals: Goal[] = [];
  private _selectedScope: Scope = null;
  private selectedDates: DateSelection = new DateSelection();

  private selectedGoalsSource = new BehaviorSubject(this._selectedGoals);
  private selectedScopeSource = new BehaviorSubject(this._selectedScope);
  private selectedDatesSource = new BehaviorSubject(this.selectedDates);

  currentSelectedGoals = this.selectedGoalsSource.asObservable();
  currentSelectedScope = this.selectedScopeSource.asObservable();
  currentSelectedDates = this.selectedDatesSource.asObservable();

  constructor() {
  }

  get selectedGoals():Goal[] {
    return this._selectedGoals;
  }

  get selectedScope():Scope {
    return this._selectedScope;
  }

  changeSelectedGoals(selectedGoals: Goal[]) {
    this.selectedGoalsSource.next(selectedGoals);
  }

  changeSelectedScope(selectedScope: Scope) {
    this.selectedScopeSource.next(selectedScope);
  }

  changeSelectedDates(selectedDates: DateSelection) {
    this.selectedDatesSource.next(selectedDates);
  }

  setSelectedDates(selectedDates: DateSelection) {
    this.selectedDates = selectedDates;
  }

  getSelectedDates():DateSelection {
    return this.selectedDates;
  }

  getSelectedGoalIds(): string {
    return this._selectedGoals.map(goal => goal.id).join(',');
  }

  getSelectedGoalNames(): string {
    return this._selectedGoals.map(goal => goal.name).join();
  }

  isFiltered(): boolean {
    return this.filtered;
  }

  setFiltered(value: boolean): void {
    this.filtered = value;
  }
}
