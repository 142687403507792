import { Component, Inject, ViewChild, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

/**
 * third party components
 */
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MessageService } from 'primeng/api';

/**
 * services
 */
import { EmailService } from '../../../services/email.service';
import {ShareStudentsFiltersData} from "../../../student-activity/share-students-filters-data";

@Component({
  selector: 'app-send-session-email',
  templateUrl: './send-session-email.component.html',
  styleUrls: ['./send-session-email.component.scss']
})
export class SendSessionEmailComponent {

  listOfEmails: string;
  format: string;

  UrlApi: string;
  parameters: string;
  userEmail: string;
  allStudentsSelected: boolean;
  students: any[] = [];

  @ViewChild('listOfEmails') inputListOfEmails: any;
  formats: string[] = ['CSV'];
  selectedFormat = this.formats[0];

  dataOptions: string[] = [this.translate.instant('send-email-modal.data-options.selected-data'),
                     this.translate.instant('send-email-modal.data-options.all-data')];
  selectedData = this.dataOptions[0];

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEventEscape(event: KeyboardEvent) {
    this.close();
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleKeyboardEventEnter(event: KeyboardEvent) {
    this.sendEmail(event);
  }

  constructor(
    public dialogRef: MatDialogRef<SendSessionEmailComponent>,
    private emailService: EmailService,
    private ng4LoadingSpinnerService: Ng4LoadingSpinnerService,
    private messageService: MessageService,
    private translate: TranslateService,
    private sharedFilters: ShareStudentsFiltersData,
    @Inject(MAT_DIALOG_DATA) data
    ) {
      this.students = data.students;
      this.parameters = data.parameters;
      this.userEmail = data.userEmail;
      this.allStudentsSelected = data.allStudentsSelected;
    }

  /**
   * close modal
   */
  close(): void {
    this.dialogRef.close();
  }

  /**
   * send emails to specific list of emails
   * using a URL API parameter
   * @param $event
   */
  sendEmail($event) {
    this.listOfEmails = this.userEmail;
    if (this.inputListOfEmails.nativeElement.value !== '') {
      this.listOfEmails = this.listOfEmails.concat(`,${this.inputListOfEmails.nativeElement.value}`);
    }
    this.ng4LoadingSpinnerService.show();
    const clientOffsetHours = (new Date()).getTimezoneOffset() / 60 * -1;
    this.UrlApi = 'emails/'.concat(this.selectedFormat.toLowerCase()).concat('/session');
    let params = `?clientOffsetHours=${clientOffsetHours}&email=${encodeURIComponent(this.listOfEmails)}${this.parameters}`;

    if (this.selectedData != this.translate.instant('send-email-modal.all-data')) {
      params = `${params}&students=${this.students}`
    }

    params += this.sharedFilters.getSelectedDates().toQueryString();

    this.emailService.sendEmail(this.UrlApi, params).then(
      () => {
        this.ng4LoadingSpinnerService.hide();
        this.close();
        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('messages.common.success'),
          detail: this.translate.instant('messages.send-email.success')
        });
      },
      (err) => {
        const errorStatus = err.error.status;
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('messages.common.error'),
          detail: this.translate.instant('messages.send-email.error')
        });
        this.ng4LoadingSpinnerService.hide();
      }
    );
  }
}
