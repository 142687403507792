import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { PaginatorModule } from 'primeng/paginator';
import { Ng4LoadingSpinnerModule  } from 'ng4-loading-spinner';

/**
 * custom imports
 */
import { SharedModules } from '../../shared/shared.module';
import { AdvancedReportingFiltersRoutingModule } from './advanced-reporting-filters-routing.module';
import { AdvancedReportingFiltersComponent } from './advanced-reporting-filters.component';
import { MaterialModule } from '../../material.module';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { EmailService } from '../../services/email.service';
import { StudentActivityFiltersComponent } from '../filters/filters.component';
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";


@NgModule({
  imports: [
    Ng4LoadingSpinnerModule.forRoot(),
    CommonModule,
    AdvancedReportingFiltersRoutingModule,
    TranslateModule.forChild({}),
    FormsModule,
    MaterialModule,
    TableModule,
    TooltipModule,
    PaginatorModule,
    ToastModule,
    // Custom modules
    SharedModules,
    NgMultiSelectDropDownModule
  ],
  declarations: [
    AdvancedReportingFiltersComponent, StudentActivityFiltersComponent
  ],
  providers: [
    MessageService,
    EmailService
  ]
})

export class AdvancedReportingFiltersModule { }
