import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class UtilsService {
  constructor () {}

  /**
   * create header for api requests
   * add Authorization token
   * other values for IE
   * @param parameterToken - if parameter exist, set localstorage
   */
  createHttpOptions(newBertToken = '') {
    let localStorageToken: String = '';
    if (newBertToken === '') {
      localStorageToken = String(localStorage.bertToken);
    } else {
      localStorageToken = newBertToken;
    }

    const httpOptions = {
      headers: new HttpHeaders ({
        'Authorization': `Bearer ${localStorageToken}`,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      })
    };
    return httpOptions;
  }

  /**
   * define headers values to set in the httoptions
   * @param {string} parameterToken
   * @returns headers values
   */
  createHeaders() {
    const localStorageToken = String(localStorage.bertToken);

    const headers = {
      'Authorization': `Bearer ${localStorageToken}`,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    };
    return headers;
  }
}
