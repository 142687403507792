import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

/**
 * model
 */
import { User } from './users.model';

export interface UsersState extends EntityState<User> {
  ui: {
    userName: string,
    college: string,
    customerId: string,
    email: string,
    url: string
  };
}

/**
 * initial state for users
 */
export function createInitialState(): UsersState {
  return {
    ui: {
      userName: '',
      college: '',
      customerId: '',
      email: '',
      url: ''
    }
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'users' })
export class UsersStore extends EntityStore<UsersState, User> {
  constructor() {
    super(createInitialState());
  }

  /**
   * update state for ui store
   * @param userName
   * @param college
   */
  updateStateUI(userName= '', college= '', customerId= '', email= '', url= '') {
    this.updateRoot( { ui: { userName, college, customerId, email, url } } );
  }
}
