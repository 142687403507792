import { Component, OnInit, Input } from '@angular/core';
import { ID } from '@datorama/akita';
import { Observable } from 'rxjs';

/**
 * state
 */
import { Path, PathQuery } from '../../state/path';
import { UsersService } from '../../state/users/users.service';
import { UsersQuery } from '../../state/users/users.query';

@Component({
  selector: 'app-path',
  templateUrl: './path.component.html',
  styleUrls: ['./path.component.scss']
})
export class PathComponent implements OnInit {

  /**
   * defining global variables
   */
  @Input() pathsIds: ID[];

  paths$: Observable<Path[]>;
  url = '';

  constructor(
    private pathQuery: PathQuery,
    private usersQuery: UsersQuery,
    private userService: UsersService,
  ) { }

  ngOnInit() {
    this.paths$ = this.pathQuery.selectMany(this.pathsIds);
    this.usersQuery.selectUrl$.subscribe((value) => {
      this.url = value;
    });
  }

  logOut(event) {
    localStorage.setItem('bertToken', '');
    this.userService.updateStateUI('', '', '', '', this.url);
  }
}
