import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

/**
 * model
 */
import { StudyPath } from './study-paths.model';

export interface StudyPathsState extends EntityState<StudyPath> {}

/**
 * initial state for users
 */
export function createInitialState(): StudyPathsState {
  return {
    general: {
      total: ''
    }
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'studyPaths' })
export class StudyPathsStore extends EntityStore<StudyPathsState, StudyPath> {
  constructor() {
    super(createInitialState());
  }

  /**
   * update general state
   * @param {string} total
   */
  updateStateGeneral(total= '') {
    this.updateRoot( { general: { total } } );
  }
}
