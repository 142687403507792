import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

/**
 * environment variables
 */
import { environment } from '../../environments/environment';

/**
 * util services
 */
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class StudentProgressService {

  /**
   * global variables
   */
  ApiUrl = environment.api;

  /**
   * create header for api request
   */
  httpOptions = this.utilsService.createHttpOptions();

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService
  ) { }

  /**
   * get student progress report data from api
   * @param studentId - studentId
   */
  getReportData(params = ''): Promise<object> {
    return new Promise((resolve, reject) => {

      let studentProgressReport = {};

      this.http.get(`${this.ApiUrl}reports/progress${params}`,
        { headers: new HttpHeaders(this.utilsService.createHeaders()), observe: 'response' }).subscribe((resp) => {
          studentProgressReport = resp.body[0];
          resolve(studentProgressReport);
        },
        err => {
          reject(err);
        });
    });
  }
}
