
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { noop } from '@datorama/akita';

/**
 * state
 */
import { Session } from './sessions.model';
import { SessionsStore } from './sessions.store';
import { SessionsQuery } from './sessions.query';

/**
 * environment variables
 */
import { environment } from '../../../environments/environment';

/**
 * services
 */
import { UtilsService } from '../../services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class SessionsService {

  /**
   * global variables
   */
  ApiUrl = environment.api;

  /**
   * set request headers - token
   */
  httpOptions = this.utilsService.createHttpOptions();

  constructor(
    private http: HttpClient,
    private sessionsStore: SessionsStore,
    private sessionsQuery: SessionsQuery,
    private utilsService: UtilsService
  ) { }

  /**
   * get session summary from api
   * set sessions store
   */
  getStudentSessionSummary(params: string = ''): Promise<any> {
    return new Promise( (resolve, reject) => {
      const sessions = this.http.get<Session[]>(`${this.ApiUrl}reports/students/activity/session-summary${params}`,
        { headers: new HttpHeaders(this.utilsService.createHeaders()), observe: 'response' });
      sessions.subscribe(resp => {
          const sessionsData: Session[] = resp.body;
          const sessionsTotal = resp.headers.get('X-Total-Count');
          const existSessions = sessionsTotal !== '0';
          this.sessionsStore.updateStateGeneral(sessionsTotal);
          this.sessionsStore.set(sessionsData);
          resolve({total: sessionsTotal, sessionData: sessionsData});
        },
        err => {
          reject(err);
        });
    });
  }

  /**
   * get students from api
   * set sessions store
   */
  getStudentsByStudyPath(params: string = ''): Promise<any> {
    return new Promise( (resolve, reject) => {
      const sessions = this.http.get<Session[]>(`${this.ApiUrl}reports/session/students${params}`,
        { headers: new HttpHeaders(this.utilsService.createHeaders()), observe: 'response' });
      sessions.subscribe(resp => {
          const sessionsData: Session[] = resp.body;
          const sessionsTotal = resp.headers.get('X-Total-Count');
          const existSessions = sessionsTotal !== '0';
          this.sessionsStore.updateStateGeneral(sessionsTotal);
          this.sessionsStore.set(sessionsData);
          resolve({total: sessionsTotal, sessionData: sessionsData});
        },
        err => {
          reject(err);
        });
    });
  }

  /**
   * get session detail from api
   * set sessions store
   */
  getStudentSessionDetail(params: string = ''): Promise<any> {
    return new Promise( (resolve, reject) => {
      const sessions = this.http.get<Session[]>(`${this.ApiUrl}reports/session/detail${params}`,
        { headers: new HttpHeaders(this.utilsService.createHeaders()), observe: 'response' });
      sessions.subscribe(resp => {
          const sessionsData: Session[] = resp.body;
          const sessionsTotal = resp.headers.get('X-Total-Count');
          const existSessions = sessionsTotal !== '0';
          this.sessionsStore.updateStateGeneral(sessionsTotal);
          this.sessionsStore.set(sessionsData);
          resolve({total: sessionsTotal, sessionData: sessionsData});
        },
        err => {
          reject(err);
        });
    });
  }

  /**
   * get session daily detail from api
   * set sessions store
   */
  getStudentSessionDaily(params: string = ''): Promise<any> {
    return new Promise( (resolve, reject) => {
      const sessions = this.http.get<Session[]>(`${this.ApiUrl}reports/session/daily${params}`,
        { headers: new HttpHeaders(this.utilsService.createHeaders()), observe: 'response' });
      sessions.subscribe(resp => {
          const sessionsData: Session[] = resp.body;
          const sessionsTotal = resp.headers.get('X-Total-Count');
          const existSessions = sessionsTotal !== '0';
          this.sessionsStore.updateStateGeneral(sessionsTotal);
          this.sessionsStore.set(sessionsData);
          resolve({total: sessionsTotal, sessionData: sessionsData});
        },
        err => {
          reject(err);
        });
    });
  }

  /**
   * get session weekly detail from api
   * set sessions store
   */
  getStudentSessionWeekly(params: string = ''): Promise<any> {
    return new Promise( (resolve, reject) => {
      const sessions = this.http.get<Session[]>(`${this.ApiUrl}reports/session/weekly${params}`,
        { headers: new HttpHeaders(this.utilsService.createHeaders()), observe: 'response' });
      sessions.subscribe(resp => {
          const sessionsData: Session[] = resp.body;
          const sessionsTotal = resp.headers.get('X-Total-Count');
          const existSessions = sessionsTotal !== '0';
          this.sessionsStore.updateStateGeneral(sessionsTotal);
          this.sessionsStore.set(sessionsData);
          resolve({total: sessionsTotal, sessionData: sessionsData});
        },
        err => {
          reject(err);
        });
    });
  }

  /**
   * get session monthly detail from api
   * set sessions store
   */
  getStudentSessionMonthly(params: string = ''): Promise<any> {
    return new Promise( (resolve, reject) => {
      const sessions = this.http.get<Session[]>(`${this.ApiUrl}reports/session/monthly${params}`,
        { headers: new HttpHeaders(this.utilsService.createHeaders()), observe: 'response' });
      sessions.subscribe(resp => {
          const sessionsData: Session[] = resp.body;
          const sessionsTotal = resp.headers.get('X-Total-Count');
          const existSessions = sessionsTotal !== '0';
          this.sessionsStore.updateStateGeneral(sessionsTotal);
          this.sessionsStore.set(sessionsData);
          resolve({total: sessionsTotal, sessionData: sessionsData});
        },
        err => {
          reject(err);
        });
    });
  }
}
