import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

/**
 * environment variables
 */
import { environment } from '../../environments/environment';

/**
 * util services
 */
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class ConsolidatedStudentsScoresService {

  /**
   * global variables
   */
  ApiUrl = environment.api;

  /**
   * create header for api request
   */
  httpOptions = this.utilsService.createHttpOptions();

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService
  ) { }

  /**
   * get consolidated report data from api
   * @param studyPaths - list of studyPaths separated by comma
   */
  getReportData(studyPaths = ''): Promise<object> {
    return new Promise((resolve, reject) => {

      let consolidatedReport = {};
      let consolidatedReportTotal;

      this.http.get(`${this.ApiUrl}reports/consolidated${studyPaths}`,
        { headers: new HttpHeaders(this.utilsService.createHeaders()), observe: 'response' })
        .subscribe( (resp) => {
          consolidatedReport = resp.body;
          consolidatedReportTotal = resp.headers.get('x-total-count') !== '' ? resp.headers.get('x-total-count') : 0;
          consolidatedReport = { ...consolidatedReport, total: consolidatedReportTotal };
          resolve(consolidatedReport);
        });
    });
  }

  /**
   * get consolidated report data structure from api
   * @param studyPaths - list of studyPaths separated by comma
   */
  getReportStructure(studyPaths = ''): Promise<object> {
    return new Promise((resolve, reject) => {

      let consolidatedReportStructure = {};

      this.http.get(`${this.ApiUrl}reports/consolidated/structure${studyPaths}`,
      { headers: new HttpHeaders(this.utilsService.createHeaders()), observe: 'response' })
        .subscribe( (resp) => {
          consolidatedReportStructure = resp.body;
          resolve(consolidatedReportStructure);
        });
    });
  }
}
