import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

/**
 * model
 */
import { Path } from './path.model';

export interface PathState extends EntityState<Path> {}

/**
 * initial state for paths
 */
export function createInitialState(): PathState {
  return {
    entities: {
      0 : {
        id: 0,
        url: '/home',
        name: 'paths.home',
        icon: ''
      },
      1 : {
        id: 1,
        url: '/consolidated_scores',
        name: 'paths.consolidated-ss',
        icon: ''
      },
      2 : {
        id: 2,
        url: '/student_progress',
        name: 'paths.student-progress',
        icon: ''
      },
      3 : {
        id: 3,
        url: '/advanced_reporting_filters',
        name: 'paths.advanced-reporting-filters',
        icon: ''
      },
      4 : {
        id: 4,
        url: '/students_list',
        name: 'paths.summary-report',
        icon: ''
      },
      5 : {
        id: 5,
        url: '/session_detail_options',
        name: 'paths.session-details',
        icon: ''
      }
    }
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'path' })
export class PathStore extends EntityStore<PathState, Path> {
  constructor(
  ) {
    super(createInitialState());
  }
}
