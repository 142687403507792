import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/**
 * environment variables
 */
import { environment } from '../../environments/environment';

/**
 * util services
 */
import { UtilsService } from './utils.service';

@Injectable()
export class EmailService {
  /**
   * global variables
   */
  ApiUrl = environment.api;

  /**
   * create header for api request
   */
  httpOptions = this.utilsService.createHttpOptions();

  constructor (
    private http: HttpClient,
    private utilsService: UtilsService
  ) {}

  /**
   * send emails using a specific api
   * send emails to specific list of mails
   * @param urlApi url for api to send emails
   * @param mailList list of mails to send
   */
  sendEmail(urlApi, mailList = ''): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.ApiUrl}${urlApi}${mailList}`, this.httpOptions).subscribe(resp => {
        resolve();
      },
      err => {
        console.log('email error', err);
        reject(err);
      });
    });
  }
}
