import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { SessionsStore, SessionsState } from './sessions.store';
import { Session } from './sessions.model';

@Injectable({
  providedIn: 'root'
})
export class SessionsQuery extends QueryEntity<SessionsState, Session> {
  /**
   * return the total value in the store - general
   * @type {Observable<string>}
   */
  selectTotal$ = this.select<string>( state => state.general.total );

  constructor(protected sessionsStore: SessionsStore) {
    super(sessionsStore);
  }
}
