import { ID } from '@datorama/akita';

export class StudyPath {
  id: ID;
  scopeId: number;
  scope: string;
  goalId: number;
  goal: string;

  constructor(id: ID, scopeId: number, scope: string, goalId: number, goal: string) {
    this.id = id;
    this.scopeId = scopeId;
    this.scope = scope;
    this.goalId = goalId;
    this.goal = goal;
  }
}
