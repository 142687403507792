import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

/**
 * state
 */
import { UsersStore, UsersState } from './users.store';
import { User } from './users.model';

@Injectable({
  providedIn: 'root'
})
export class UsersQuery extends QueryEntity<UsersState, User> {
  selectUserName$ = this.select<string>(state => state.ui.userName);
  selectCollege$ = this.select<string>(state => state.ui.college);
  selectCustomerId$ = this.select<string>(state => state.ui.customerId);
  selectEmail$ = this.select<string>(state => state.ui.email);
  selectUrl$ = this.select<string>(state => state.ui.url);

  constructor(protected usersStore: UsersStore) {
    super(usersStore);
  }
}
