import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

/**
 * model
 */
import { Session } from './sessions.model';

export interface SessionsState extends EntityState<Session> {}

/**
 * initial state for students
 */
export function createInitialState(): SessionsState {
  return {
    general: {
      total: ''
    }
  };
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'sessions' })
export class SessionsStore extends EntityStore<SessionsState, Session> {
  constructor() {
    super(createInitialState());
  }

  /**
   * update general state
   * @param {string} total
   */
  updateStateGeneral(total= '') {
    this.updateRoot( { general: { total } } );
  }
}
