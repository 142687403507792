import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { ThemesStore, ThemeState } from './themes.store';
import { Theme } from './themes.model';

@Injectable({
  providedIn: 'root'
})
export class ThemesQuery extends QueryEntity<ThemeState, Theme> {
  selectLogoUrl$ = this.select<string>(state => state.ui.logoUrl);

  constructor(protected themesStore: ThemesStore) {
    super(themesStore);
  }

}
