import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * state
 */
import { UsersQuery } from '../../state/users';
import { ThemesQuery } from '../../state/themes';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  /**
   * definitions for global variables
   */
  username$: Observable<string>;
  logoUrl$: Observable<string>;

  constructor(
    private usersQuery: UsersQuery,
    private themesQuery: ThemesQuery,
  ) { }

  ngOnInit() {
    this.logoUrl$ = this.themesQuery.selectLogoUrl$;
    this.username$ = this.usersQuery.selectUserName$;
  }
}
