import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { StudentsStore, StudentsState } from './students.store';
import { Student } from './students.model';

@Injectable({
  providedIn: 'root'
})
export class StudentsQuery extends QueryEntity<StudentsState, Student> {
  /**
   * return the total value in the store - general
   * @type {Observable<string>}
   */
  selectTotal$ = this.select<string>( state => state.general.total );

  constructor(protected studentsStore: StudentsStore) {
    super(studentsStore);
  }
}
